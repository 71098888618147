<template>
    <druk-dropdown :position="{ horizontal: 'end', vertical: 'bot' }" :hasStaticContent="true">
        <template v-slot:druk-c-dd-base="{ isShown }">
            <tool-tip :text="$t('library.works.material_formulas.standard_formulas')" :isFrozen="isShown">
                <druk-icon-toggle-button :type="'tonal'" :icon="'square-root-variable'" :isActive="isShown" />
            </tool-tip>
        </template>

        <template v-slot:druk-c-dd-list>
            <ul class="list">
                <li v-for="(item, index) in standardFormulas" :key="index" class="list__item" @click="onSet(item.formula)">
                    {{ $t(item.alias_translation) }}
                </li>
            </ul>
        </template>
    </druk-dropdown>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'calc-formula-standard',

        computed: {
            ...mapGetters({
                standardFormulas: 'calcModes/formula/standardFormulas',
            }),
        },

        methods: {
            ...mapActions({
                SET_FORMULA_AREA: 'calcModes/formula/SET_AREA',
            }),

            ...mapMutations({
                RESET_FORMULA_AREA: 'calcModes/formula/RESET_AREA',
            }),

            onSet(formula) {
                this.RESET_FORMULA_AREA();
                this.SET_FORMULA_AREA(formula);

                this.$bus.$emit('on-area-update');
                this.$noty.success(this.$t('library.works.material_formulas.set_fomula.success'));
            },
        },
    };
</script>
