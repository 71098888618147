<template>
    <div
        class="selector selector--date-picker"
        ref="selector"
        :class="{
            'has-focus': hasActive,
            'has-full': hasFull,
            'has-error': errors.has(name),
            'has-clear-icon': isFilterType,
            'selector--label-date-picker': !label && singleDatePicker,
        }">
        <div v-if="label" class="selector__label" :class="{ [`druk-l-surface-${surface}`]: surface }">
            <span>
                <label>{{ label }}</label>
                <i v-if="hasRequired">*</i>
            </span>

            <druk-hint
                v-if="hint"
                :tooltip="{ text: hint.text || hint, from: 'top', maxWidth: hint.maxWidth, isNotCollapse: hint.isNotCollapse }"
                :icon="{ name: 'circle-info', size: 'xs', color: hasActive ? 'primary' : 'outline' }" />
        </div>

        <div class="selector__main" :class="{ [`druk-l-surface-${surface}`]: surface }">
            <date-range-picker
                v-model="dateRange"
                ref="picker"
                :style="{ [`--druk-content-background`]: contentBackground }"
                :opens="opens || 'left'"
                :locale-data="locale"
                :ranges="ranges"
                :maxDate="maxDate"
                :singleDatePicker="singleDatePicker || false"
                :showDropdowns="showDropdowns || false"
                @toggle="hasActive = !hasActive"
                @update="update">
                <div class="selector__date-value" slot="input">
                    {{
                        dateRange.startDate && dateRange.endDate && !this.singleDatePicker
                            ? this.$options.filters.date(dateRange.startDate) + ' – ' + this.$options.filters.date(dateRange.endDate)
                            : dateRange.startDate && dateRange.endDate && this.singleDatePicker
                              ? this.$options.filters.date(dateRange.endDate)
                              : ''
                    }}
                </div>

                <div class="selector__date-icon" v-if="!hasFull || !isFilterType" slot="input">
                    <font-awesome-icon icon="fa-regular fa-calendar" />
                </div>

                <div class="selector__clear" v-if="isFilterType && hasFull" slot="input" @click="onClear">
                    <font-awesome-icon icon="fa-regular fa-xmark" />
                </div>

                <div class="selector__date-footer" :slot="'footer'" slot-scope="props">
                    <span v-if="hasSelectedRange(props.rangeText)" class="selector__date-value">{{
                        props.rangeText.replaceAll('/', '.')
                    }}</span>

                    <druk-button class="druk-u-margin-right-4" :type="'text'" :label="$t('btn.cancel')" @click="props.clickCancel" />

                    <druk-button :type="'text'" :label="$t('status.accept')" @click="props.clickApply" />
                </div>
            </date-range-picker>
        </div>

        <div class="selector__observer">
            <input :value="parsedValue" ref="observer" :data-vv-name="name" :data-vv-as="label" v-validate="rules || ''" :key="name" />
        </div>

        <div v-if="errors.has(name)" class="selector__error">
            <span :class="{ [`druk-l-surface-${surface}`]: surface }">{{ errorText || errors.first(name) }}</span>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import DateRangePicker from 'vue2-daterange-picker';

    import { debounce } from 'lodash';

    export default {
        name: 'form-date-picker',

        inject: ['$validator'],

        components: { DateRangePicker },

        props: {
            value: {
                required: true, //obj = {from: '2021-07-14', to: '2021-08-28'}
            },
            name: String,
            label: String,
            opens: String,
            singleDatePicker: Boolean,
            showDropdowns: Boolean,
            hasDateLimit: Boolean,
            isFilterType: Boolean,
            hint: [String, Object],
            rules: String,
            errorText: String,

            surface: {
                type: String,
                default: 'tint-pale',
            },

            surfaceVariant: {
                type: String,
                default: 'tint-bright',
            },
        },

        data() {
            return {
                BOTTOM_MARGIN: 10, //Margin for datepicker dropdown

                dateRange: {
                    startDate: null,
                    endDate: null,
                },

                locale: {
                    direction: 'ltr', //direction of text
                    format: 'dd/mm/yyyy',
                    separator: ' – ', //separator between the two ranges
                    applyLabel: this.$t('status.accept'),
                    cancelLabel: this.$t('status.cancel'),
                    weekLabel: 'W',
                    customRangeLabel: this.$t('picker.label.range'),

                    daysOfWeek: [
                        this.$t('admin.week.day1'),
                        this.$t('admin.week.day2'),
                        this.$t('admin.week.day3'),
                        this.$t('admin.week.day4'),
                        this.$t('admin.week.day5'),
                        this.$t('admin.week.day6'),
                        this.$t('admin.week.day7'),
                    ],

                    monthNames: [
                        this.$t('month.type1'),
                        this.$t('month.type2'),
                        this.$t('month.type3'),
                        this.$t('month.type4'),
                        this.$t('month.type5'),
                        this.$t('month.type6'),
                        this.$t('month.type7'),
                        this.$t('month.type8'),
                        this.$t('month.type9'),
                        this.$t('month.type10'),
                        this.$t('month.type11'),
                        this.$t('month.type12'),
                    ],

                    firstDay: 1, //ISO first day of week - see moment documenations for details
                },

                disabledDates: {
                    ranges: [],
                },

                topPosition: null,

                selectedRange: null,

                hasActive: false,
            };
        },

        created() {
            this.setDateRange();
        },

        watch: {
            value() {
                this.setDateRange();
            },

            hasActive() {
                this.setTopPosition();
            },
        },

        computed: {
            ranges() {
                let today = new Date();
                today.setHours(0, 0, 0, 0);

                let yesterday = new Date();
                yesterday.setDate(today.getDate() - 1);
                yesterday.setHours(0, 0, 0, 0);

                return {
                    [this.$t('task.today')]: [today, today],
                    [this.$t('task.yesterday')]: [yesterday, yesterday],
                    [this.$t('common.this_month')]: [
                        new Date(today.getFullYear(), today.getMonth(), 1),
                        new Date(today.getFullYear(), today.getMonth() + 1, 0),
                    ],
                    [this.$t('common.last_month')]: [
                        new Date(today.getFullYear(), today.getMonth() - 1, 1),
                        new Date(today.getFullYear(), today.getMonth(), 0),
                    ],
                    [this.$t('common.this_year')]: [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
                };
            },

            maxDate() {
                return this.hasDateLimit ? moment().format('YYYY-MM-DD') : null;
            },

            parsedValue() {
                return `${this.dateRange.startDate || ''}${this.dateRange.endDate || ''}`;
            },

            contentBackground() {
                let base = this.surfaceVariant.split('-');
                return `var(--druk-${[...base].shift()}-surface-container-${[...base].pop()})`;
            },

            hasFull() {
                if (!this.dateRange) return false;
                return this.dateRange.startDate || this.dateRange.endDate;
            },

            hasRequired() {
                if (this.rules && this.rules.indexOf('required') != -1) return true;
                return false;
            },
        },

        filters: {
            date(val) {
                return val ? moment(val).format('YYYY-MM-DD') : '';
            },
        },

        methods: {
            setDateRange() {
                this.$set(this.dateRange, 'startDate', this.value.from);
                this.$set(this.dateRange, 'endDate', this.value.to);

                if (this.$refs.observer) this.$validator.validate(this.name, this.parsedValue);
            },

            update(date) {
                let from = moment(date.startDate).format('YYYY-MM-DD'),
                    to = moment(date.endDate).format('YYYY-MM-DD');

                this.$emit('input', { from, to });
            },

            onClear() {
                this.$emit('input', {
                    from: null,
                    to: null,
                });
            },

            setTopPosition: debounce(function () {
                let dropHeight = this.$refs.picker?.$refs.dropdown?.offsetHeight,
                    availableArea = window.innerHeight - this.$refs.selector?.getBoundingClientRect().top;

                if (dropHeight && dropHeight > availableArea) {
                    this.$refs.picker.$refs.dropdown.style.cssText = `bottom: 100%`;
                    // this.$refs.picker.$refs.dropdown.style.cssText = `bottom: calc(100% - ${this.$refs.selector.offsetHeight}px);`;
                }
            }, 0), //Wait for the opening animation

            hasSelectedRange(range) {
                return !!range.split('–').filter((date) => date != ' ').length;
            },
        },
    };
</script>

<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>

<style lang="scss"></style>
