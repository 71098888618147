import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        list: [],
        listCast: [],
        listStash: [],
    },

    getters: {
        formattedList(state) {
            return state.list.map((item, index) => {
                let start = item.count > 1 ? item.count : 1;
                let end = state.list[index + 1] ? state.list[index + 1].count - 1 : '...';

                return {
                    label: `${start}-${end}`,
                    price: item.price,
                };
            });
        },
    },

    actions: {
        DELETE_LIST: ({ state, getters, commit }) => {
            commit('RESET_LIST');
            commit('STASH_LIST');
        },
    },

    mutations: {
        SET_LIST(state, list) {
            let sortedList = list.sort((prev, next) => +prev.count - +next.count),
                indexedList = sortedList.map((item, index) => Object.assign(item, { index }));

            Vue.set(state, 'list', indexedList);
            Vue.set(
                state,
                'listCast',
                state.list.map((item) => ({ ...item })),
            );
            Vue.set(
                state,
                'listStash',
                state.list.map((item) => ({ ...item })),
            );
        },

        SET_ITEM_DATA(state, { index, key, value }) {
            if (index === 'undefined' || !value) return;

            let itemIndex = state.listCast.findIndex((item) => item.index === index);
            Vue.set(state.listCast[itemIndex], key, value);
        },

        UPDATE_LIST(state) {
            state.list = state.listCast
                .filter((item) => item.price !== null && item.price !== undefined && item.count)
                .sort((prev, next) => +prev.count - +next.count)
                .map((item, index) => ({ ...item, index }));
        },

        UPDATE_LIST_CAST(state) {
            state.listCast = state.listCast.sort((prev, next) => +prev.count - +next.count).map((item, index) => ({ ...item, index }));
        },

        ADD_ITEM(state, item) {
            let newItem = item || { count: null, price: null, index: -1 };
            state.listCast.push({ ...newItem });
        },

        DELETE_ITEM(state, index) {
            let itemIndex = state.listCast.findIndex((item) => item.index === index);
            Vue.delete(state.listCast, itemIndex);
        },

        STASH_LIST(state) {
            state.listStash = state.list;
        },

        RESTORE_LIST(state) {
            Vue.set(
                state,
                'list',
                state.listStash.map((item) => ({ ...item })),
            );
            Vue.set(
                state,
                'listCast',
                state.listStash.map((item) => ({ ...item })),
            );
        },

        RESET_LIST(state) {
            if (state.list.length)
                Vue.set(
                    state,
                    'listStash',
                    state.list.map((item) => ({ ...item })),
                );

            state.list = [];
            state.listCast = [];
        },

        RESET_LIST_CAST(state) {
            state.listCast = state.list.map((item, index) => ({ ...item, index }));
        },

        RESET_LIST_CAST_ITEM(state, index) {
            let itemIndex = state.listCast.findIndex((item) => item.index === index);
            Vue.set(state.listCast, itemIndex, {
                ...state.list[itemIndex],
                index: itemIndex,
            });
        },
    },
};
