<template>
    <div class="check__group">
        <form-checkbox
            v-for="(option, index) in options"
            :key="index"
            v-model="checkList[index]"
            :label="option.title"
            @change="pickOption(item)" />

        <span v-if="hasError" class="form__error">{{ errorText }}</span>
    </div>
</template>

<script>
    export default {
        name: 'form-check-group',

        props: {
            value: Array,
            name: String,
            options: Array,
            rules: [String, Object],
            errorText: String,

            hasError: Boolean,
        },

        data() {
            return {
                checkList: this.value || [],
            };
        },

        methods: {
            pickOption(item) {
                let pickedItem = item;
                pickedItem.picked = true;
                this.$emit('input', this.checkList);
            },
        },
    };
</script>

<style scoped lang="scss">
    // .check {
    // 	&__group {
    // 		display: flex;
    // 		flex-wrap: wrap;
    // 		align-items: center;
    // 		justify-content: space-between;
    // 	}
    // 	&__item {
    // 		cursor: pointer;
    // 		width: 50%;
    // 		display: flex;
    // 		align-items: center;
    // 		justify-content: flex-start;
    // 		padding-bottom: 10px;
    // 		margin-bottom: 10px;
    // 		@media(max-width: $druk-breakpoints-sm) {
    // 			width: 100%;
    // 		}
    // 		&:last-child {
    // 			border-bottom: none;
    // 		}
    // 		&:hover {
    // 			i{
    // 				border: 2px solid $c_alt;
    // 			}
    // 			span{
    // 				color: $c_alt;
    // 			}
    // 		}
    // 		&.has-checked {
    // 			pointer-events: none;
    // 			i{
    // 				background: #eee;
    // 			}
    // 		}
    // 		input {
    // 			display: none;
    // 			&:checked{
    // 				& ~ {
    // 					i svg{
    // 						opacity: 1;
    // 					}
    // 				}
    // 			}
    // 		}
    // 		i {
    // 			margin-right: 10px;
    // 			width: 24px;
    // 			height: 24px;
    // 			display: block;
    // 			border: 2px solid #C7D3D6;
    // 			flex-shrink: 0;
    // 			transition: all 0.3s ease-in-out;
    // 		}
    // 		svg {
    // 			width: 15px;
    // 			height: 15px;
    // 			position: relative;
    // 			bottom: 4px;
    // 			left: 1px;
    // 			opacity: 0;
    // 			transition: all 0.3s;
    // 			transform: translate(2px, 2px);
    // 			fill: $c_error;
    // 		}
    // 		span {
    // 			font-size: 14px;
    // 			transition: all 0.3s ease-in-out;
    // 		}
    // 	}
    // }
</style>
