import Vue from 'vue';

const DEFAULT_FILTERS = {
    title: null,
    has_contour: null,
    page: null,
    limit: 30,
    sort: 'new',
    direction: 'desc',
};

export default {
    namespaced: true,

    state: {
        CONTOUR_OPTIONS: [
            {
                title_translation_alias: 'formats.filters.contour.has_no_contour',
                value: 0,
            },
            {
                title_translation_alias: 'formats.filters.contour.has_contour',
                value: 1,
            },
        ],

        list: [],
        listPagination: {},

        rootList: [],

        item: {},

        listFilter: { ...DEFAULT_FILTERS },

        loadingGetList: false,
        loadingGetRootList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        formattedList(state) {
            return state.list.map((item) => Object.assign({ ...item }, { title: item.title || item.original_title }));
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_ROOT_LIST(state, list) {
            state.rootList = list;
        },

        SET_BY_MATERIAL_LIST(state, list) {
            state.byMaterialList = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_STORE(state) {
            state.listFilter = { ...DEFAULT_FILTERS };
        },

        RESET_ROOT_LIST(state) {
            state.rootList = [];
        },

        RESET_ITEM(state) {
            state.item = {};
        },
    },

    actions: {
        GET_LIST: async ({ rootState, rootGetters, state, getters, commit }, filters = {}) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = rootGetters.hasTypographyAccess
                        ? `/api/typographies/${rootGetters.currentTypography.id}/formats`
                        : `/api/formats`,
                    currentFilters = Object.keys(filters).length ? filters : getters.listActiveFilter;

                let resp = await $axios.get(path, { params: currentFilters });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ROOT_LIST: async ({ state, getters, rootGetters, commit }, filters = {}) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetRootList', status: true });

                let resp = await $axios.get(`/api/formats`, { params: filters });

                commit('SET_ROOT_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetRootList', status: false });
            }
        },

        GET_ITEM: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/formats/${id}`
                    : `/api/formats/${id}`;

                let resp = await $axios.get(path);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ rootState, rootGetters, commit, dispatch }, { formData, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/formats`
                    : `/api/formats`;

                let resp = await $axios.post(path, formData);

                if (!without_fetch) dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        ATTACH_ITEM: async ({ state, commit, rootGetters, dispatch }, { id, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/formats/attach/${id}`, formData);

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/formats/${id}`
                    : `/api/formats/${id}`;

                let resp = await $axios.delete(path);

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
