<template>
    <div class="tt" :class="{ 'is-inline': isInline, 'druk-is-frozen': isFrozen }" @click="onPopup()">
        <div class="tt__body" :class="{ 'is-popup': popupMode, 'is-common': !popupMode }">
            <slot></slot>

            <div ref="tip" class="tt__tip" :class="{ [`from-${from}`]: from }">
                <div :style="{ '--max-width': maxWidth }" class="tt__text" :class="{ 'is-not-collapse': isNotCollapse }">
                    <span v-if="hasUnparsedText">{{ text }}</span>
                    <span v-else v-html="text"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'tool-tip',

        props: {
            text: [String, Number],
            from: String,
            // top, top-right, top-left
            // right
            // bottom, bottom-right, bottom-left
            // left
            popupMode: Boolean,

            maxWidth: {
                type: String,
                default: '120px',
            },

            isInline: Boolean,
            isNotCollapse: Boolean,
            isFrozen: Boolean,
            hasUnparsedText: Boolean,
        },

        methods: {
            onPopup() {
                if (this.popupMode) {
                    this.$refs.tip.classList.add('is-animated');

                    setTimeout(() => {
                        // waiting ending css animation
                        this.$refs.tip.classList.remove('is-animated');
                    }, 850);
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .tt {
        cursor: pointer;
        &.is-inline {
            display: inline-block;
            vertical-align: text-top;
        }
        &.druk-is-frozen {
            pointer-events: none;
        }
        &__body {
            position: relative;
            &.is-common {
                &:hover {
                    .tt__tip {
                        opacity: 1;
                        transform: translate(-50%, 0) scale(1);
                        &.from-top-left,
                        &.from-top-right,
                        &.from-bottom-left,
                        &.from-bottom-right {
                            transform: translate(0, 0) scale(1);
                        }
                        &.from-right,
                        &.from-left {
                            transform: translate(0%, -50%) scale(1);
                        }
                    }
                }
            }
        }
        &__tip {
            cursor: pointer;
            pointer-events: none;
            position: absolute;
            top: initial;
            right: initial;
            bottom: initial;
            left: 50%;
            padding: 4px 0px;
            opacity: 0;
            transform: translate(-50%, 0) scale(0.85);
            transform-origin: top;
            transition:
                opacity var(--druk-duration-short-4) var(--druk-easing-emphasized-accelerate),
                transform var(--druk-duration-short-2) var(--druk-easing-emphasized-accelerate);
            z-index: 10;
            &.from-top {
                top: initial;
                right: initial;
                bottom: 100%;
                left: 50%;
                padding: 4px 0px;
                transform: translate(-50%, 0) scale(0.85);
            }
            &.from-top-left {
                top: initial;
                right: 0;
                bottom: 100%;
                left: initial;
                padding: 4px 0px;
                transform-origin: right;
                transform: translate(0, 0) scale(0.85);
            }
            &.from-top-right {
                top: initial;
                right: initial;
                bottom: 100%;
                left: 0;
                padding: 4px 0px;
                transform-origin: left;
                transform: translate(0, 0) scale(0.85);
            }
            &.from-right {
                top: 50%;
                right: initial;
                bottom: initial;
                left: 100%;
                padding: 0px 4px;
                transform-origin: left;
                transform: translate(0%, -50%) scale(0.85);
            }
            &.from-bottom {
                top: initial;
                right: initial;
                bottom: initial;
                left: 50%;
                padding: 4px 0px;
                transform: translateX(-50%, 0) scale(0.85);
            }
            &.from-bottom-left {
                top: initial;
                right: initial;
                bottom: initial;
                left: initial;
                padding: 4px 0px;
                transform-origin: left;
                transform: translate(0, 0) scale(0.85);
            }
            &.from-bottom-right {
                top: initial;
                right: 0;
                bottom: initial;
                left: initial;
                padding: 4px 0px;
                transform-origin: right;
                transform: translate(0, 0) scale(0.85);
            }
            &.from-left {
                top: 50%;
                right: 100%;
                bottom: initial;
                left: initial;
                padding: 0px 4px;
                transform-origin: right;
                transform: translate(0%, -50%) scale(0.85);
            }
            &.is-animated {
                top: 0px;
                left: initial;
                right: calc(100% + 5px);
                transform: scale(1);
                opacity: 1;
                animation: hide 1s;
                @keyframes hide {
                    0% {
                        top: 0px;
                        opacity: 0;
                    }
                    10% {
                        top: 0px;
                        opacity: 0.5;
                    }
                    30% {
                        top: 0px;
                        opacity: 1;
                    }
                    to {
                        top: -100%;
                        opacity: 0;
                    }
                }
            }
        }
        &__text {
            padding: 4px 8px;
            width: max-content;
            max-width: var(--max-width);
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            font-weight: 400;
            border-radius: 4px;
            color: var(--druk-inverse-on-surface);
            background-color: var(--druk-surface-inverse-surface);
            &.is-not-collapse {
                max-width: initial;
            }
        }
    }
</style>
