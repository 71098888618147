<template>
    <div
        class="selector selector--input selector--input-num"
        :class="{
            'has-focus': hasActive,
            'has-full': hasFull,
            'has-error': errors.has(name) || hasError,
            'has-loading': loader,
        }">
        <div class="selector__label" :class="{ [`druk-l-surface-${surface}`]: surface }" @click="onToggle">
            <span>
                <label>{{ label }}</label>
                <i v-if="hasRequired">*</i>
            </span>

            <druk-hint
                v-if="hint"
                :tooltip="{ text: hint.text || hint, from: 'top', maxWidth: hint.maxWidth, isNotCollapse: hint.isNotCollapse }"
                :icon="{ name: 'circle-info', size: 'xs', color: hasActive ? 'primary' : 'outline' }" />
        </div>

        <div class="selector__main" :class="{ [`druk-l-surface-${surface}`]: surface }">
            <input
                :value="value"
                ref="area"
                :name="name"
                :placeholder="placeholder"
                :data-vv-name="name"
                :data-vv-as="errorLabel || validatorLabel || label"
                v-validate="rules || ''"
                :type="type || 'text'"
                :list="list"
                :maxlength="maxlength"
                @focus="hasActive = true"
                @blur="hasActive = false"
                @keyup.enter="$emit('enter')"
                @keyup="$emit('keyup')"
                @input="onInput($event.target.value)"
                class="selector__area" />

            <div class="selector__buttons">
                <div class="selector__button" @click="onUp(+value + 1)">
                    <font-awesome-icon icon="fa-regular fa-plus" />
                </div>

                <div class="selector__button" @click="onDown(+value - 1)">
                    <font-awesome-icon icon="fa-regular fa-minus" />
                </div>
            </div>
        </div>

        <div v-if="errors.has(name)" class="selector__error">
            <span :class="{ [`druk-l-surface-${surface}`]: surface }">{{ errorText || errors.first(name) }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'form-input-num',
        inject: ['$validator'],
        props: {
            value: {
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            min: {
                default: 0,
                type: [String, Number],
            },
            max: [String, Number],
            placeholder: String,
            type: String,
            maxlength: String,
            label: String,
            validatorLabel: String,
            errorLabel: String,
            translate: String,
            rules: [String, Object],
            filterType: Boolean,
            errorText: String,
            hasError: {
                default: false,
                type: Boolean,
            },

            surface: {
                type: String,
                default: 'tint-pale',
            },

            list: String,
            hint: [String, Object],
            loader: Boolean,
        },
        data() {
            return {
                hasActive: false,
            };
        },

        watch: {
            max() {
                this.onFocusOut();
            },

            mix() {
                this.onFocusOut();
            },

            loader() {
                this.onRemoveFocus();
            },
        },

        computed: {
            hasFull() {
                return !!this.value;
            },

            hasRequired() {
                if (this.rules && (typeof this.rules === 'string' ? this.rules.indexOf('required') !== -1 : this.rules.required))
                    return true;
                return false;
            },
        },

        methods: {
            onClear() {
                if (!this.hasFull) return;
                this.$emit('input', null);
            },

            onInput(val) {
                this.$emit('input', +val);
            },

            onUp(val) {
                if (val > +this.max) val = +this.max;
                this.$emit('input', val);
            },

            onDown(val) {
                if (val < +this.min) val = +this.min;
                this.$emit('input', val);
            },

            onFocusOut() {
                this.hasActive = false;

                let currentVal = +this.value;

                if (this.value < +this.min) currentVal = +this.min;
                if (this.value > +this.max) currentVal = +this.max;

                this.$emit('input', currentVal);
                this.$validator.validate(this.name);
            },

            onRemoveFocus() {
                this.hasActive = false;
                this.$refs.area.blur();
            },

            onToggle() {
                this.$refs.area.focus();
            },
        },

        // @TODO: It's look like uneccessary super-logic. Maybe will be need remove that in the future, with project adaptation
        mounted() {
            let selectorLabel = this.$el.querySelector('.selector__label'),
                labelOffsetHeight = selectorLabel.getElementsByTagName('label')[0].offsetHeight;

            if (labelOffsetHeight > 30) selectorLabel.className += ' selector__label--double';
        },
    };
</script>

<style lang="scss" scoped></style>
