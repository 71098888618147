var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form__item",class:{ 'druk-u-margin-bottom-0': _vm.hasAltStyles }},[_c('div',{staticClass:"ranges"},[_c('div',{staticClass:"ranges__wrapper",attrs:{"id":_vm.WRAPPER_ID}},[(!_vm.hasPreviewMode)?_c('div',{staticClass:"ranges__main"},[_c('div',{staticClass:"ranges__slider"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.hasLoadedRanges)?_c('vue-slider',{class:{ 'has-inaccessible': _vm.hasBlockedSlider },attrs:{"min":_vm.min,"max":_vm.max,"process":_vm.process,"dotOptions":_vm.dotOptions,"dotSize":16,"tooltip":'always',"tooltipPlacement":'top',"contained":true,"clickable":false,"enableCross":false},scopedSlots:_vm._u([{key:"tooltip",fn:function({ value, index }){return [_c('div',{staticClass:"ranges__tooltip",class:{
                                        'ranges__tooltip--bottom': index % 2,
                                        'ranges__tooltip--top': !(index % 2),
                                        'has-alt': _vm.hasAltStyles,
                                    }},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"dot",fn:function({ index, value }){return [_c('calc-range-save',{attrs:{"index":index + 1,"hasEditType":true,"hasContextMode":true},on:{"onClose":_vm.resetBlock,"onUpdate":_vm.onStartRangesUpdate}},[_c('tool-tip',{attrs:{"text":value,"from":'top'}},[_c('div',{staticClass:"ranges__dot",on:{"mousedown":function($event){return _vm.onFocus($event, index)}}},[_c('span',{staticClass:"ranges__mark"})])])],1)]}}],null,false,3751825782),model:{value:(_vm.rangesData),callback:function ($$v) {_vm.rangesData=$$v},expression:"rangesData"}}):_vm._e()],1),_c('div',{staticClass:"ranges__line"},[_c('vue-slider',{attrs:{"data":_vm.lineData,"processStyle":_vm.lineStyle,"dotOptions":_vm.lineDotOptions,"dotSize":16,"tooltip":'none',"contained":true,"clickable":false},scopedSlots:_vm._u([{key:"dot",fn:function({ index }){return [_c('calc-range-save',{attrs:{"index":index,"surface":_vm.surface,"hasEditType":true,"hasContextMode":true,"hasInaccessible":index > 0},on:{"onClose":_vm.resetBlock,"onUpdate":_vm.onStartRangesUpdate}},[_c('div',{staticClass:"ranges__dot",on:{"mousedown":function($event){return _vm.onFocus($event, index)}}},[(index === 0)?_c('span',{staticClass:"ranges__mark"}):_vm._e()])])]}}],null,false,2196791277),model:{value:(_vm.lineValue),callback:function ($$v) {_vm.lineValue=$$v},expression:"lineValue"}})],1)],1),_c('calc-range-save',{attrs:{"index":-1,"lockedValues":_vm.rangesData,"side":'has-from-right',"surface":_vm.surface},on:{"onUpdate":_vm.onStartRangesUpdate}},[_c('druk-icon-button',{attrs:{"type":'outlined',"icon":'plus'},on:{"click":function($event){return _vm.onAddRange()}}})],1)],1):_vm._e(),(!_vm.hasPreviewMode)?_c('druk-support',{staticClass:"druk-u-margin-bottom-16"},[_vm._v(_vm._s(_vm.$t('ranges.prompt')))]):_vm._e(),_c('div',{staticClass:"section__card",class:{
                    'section__card druk-u-margin-bottom-0': _vm.hasAltStyles,
                    [`druk-l-surface-${_vm.surfaceVariant}`]: _vm.surfaceVariant,
                }},[_c('calc-ranges-chart',{attrs:{"chartTitle":_vm.$t(`${_vm.translatesBase}.range.title`)}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }