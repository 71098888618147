import { forIn } from 'lodash';

import groups from './groups';
import variables from './variables';
import params from './params';

export default {
    namespaced: true,
    modules: {
        groups,
        variables,
        params,
    },
    state: {
        list: [],
        item: {},
        listPagination: {},
        listFilter: {
            page: null,
            limit: 30,
            title: null,
        },

        loadingGetList: false,
        loadingGetItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },
        SET_ITEM(state, item) {
            state.item = item;
        },
        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },
        SET_FILTER_LIST(state, { key, val }) {
            state.listFilter[key] = val;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },
    },

    actions: {
        GET_ALL_OPTIONS_BY_TR: async ({ state, rootGetters, getters, commit }, ids) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/equipments/params/tree`, {
                    params: {
                        ids: ids,
                    },
                });

                commit('equipments/params/SET_PARAM_OPTIONS_LIST', resp.list, {
                    root: true,
                });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_LIST: async ({ state, rootGetters, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/equipments/groups/tree`, {
                    params: getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/equipments/${id}/tables`, {
                    params: $fn.assign(getters.listActiveFilter, {
                        limit: 1000,
                    }),
                });

                commit('SET_ITEM', {
                    list: resp.list,
                    equipments: resp.equipments,
                });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        GET_ITEM_TR: async ({ state, rootGetters, getters, commit }, { equipmentsId, id }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let resp = await $axios.get(
                    `/api/typographies/${rootGetters.currentTypography.id}/equipments/${equipmentsId}/tables/${id}`,
                );

                commit(
                    'equipments/variables/SET_VARIABLE',
                    {
                        type: 'set',
                        variables: resp.single.variables,
                    },
                    { root: true },
                );

                commit('equipments/params/SET_REQUEST_PARAMS', resp.single.options, {
                    root: true,
                });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, rootGetters, rootState, commit, dispatch }, { formData, paramsIds, id }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/equipments/params/${id}/options`,
                    formData,
                );

                let paramIds = [];
                dispatch('GET_ALL_OPTIONS_BY_TR', paramsIds);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        SAVE_ITEM_TR: async ({ state, rootGetters, commit, dispatch }, { maretiadId, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/equipments/${maretiadId}/tables`,
                    formData,
                );

                dispatch('GET_ITEM', maretiadId);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        SAVE_ITEM_GROUP: async ({ state, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/equipments/groups`, formData);

                dispatch('GET_LIST');

                if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let resp = await $axios.delete(`/api/typographies/${rootGetters.currentTypography.id}/equipments/${id}`);
                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },

        DELETE_ITEM_TR: async ({ commit, rootGetters, dispatch }, { equipmentsId, id }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let resp = await $axios.delete(
                    `/api/typographies/${rootGetters.currentTypography.id}/equipments/${equipmentsId}/tables/${id}`,
                );
                dispatch('GET_ITEM', equipmentsId);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
