<template>
    <div class="wrapper" :class="{ 'has-elusive-thief': hasElusiveThief }">
        <elusive-thief v-if="hasElusiveThief" />

        <header class="wrapper__header">
            <header-top :hasIframe="true"></header-top>
        </header>

        <div class="wrapper__main">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import ElusiveThief from '@common/doppler/ElusiveThief';
    import HeaderTop from '@common/header/HeaderTop';

    export default {
        name: 'iframe-layout',

        components: {
            ElusiveThief,
            HeaderTop,
        },

        computed: {
            ...mapGetters({
                isLogged: 'isLogged',
                hasElusiveThief: 'hasElusiveThief',
            }),
        },

        created() {
            if (!this.isLogged) {
                this.onLogout();
            }
        },

        methods: {
            ...mapMutations({
                SET_HISTORY_ADDRESS: 'SET_HISTORY_ADDRESS',
            }),

            onLogout() {
                this.SET_HISTORY_ADDRESS(window.location.href);
                this.$router.push({ name: 'login' });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .wrapper {
        transition: all 0.3s;
        // background-color: $c_white;
        background-color: var(--druk-background);
        height: 100%;
        display: flex;
        flex-direction: column;
        &.has-active-drawer {
            padding-left: 250px;
            @media (max-width: $druk-breakpoints-md) {
                overflow: hidden;
            }
        }
        &__header {
            position: relative;
            z-index: 5;
        }
        @media (max-width: $druk-breakpoints-md) {
            padding-left: 0;
        }
    }
</style>
