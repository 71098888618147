<template>
    <div class="radio control" :class="{ 'is-column': isColumn }">
        <div v-for="(item, index) in options" :key="index" class="radio__item">
            <label :for="`${name}_${item.value}`" class="radio__button control__button" :class="{ 'is-active': item.value === value }">
                <span class="radio__hover control__hover"></span>

                <span class="radio__icon control__icon">
                    <font-awesome-icon v-if="item.value === value" icon="fa-solid fa-circle-small" />
                </span>
            </label>

            <input
                type="radio"
                :id="`${name}_${item.value}`"
                :value="item.value"
                v-model="radio"
                class="radio__input control__input"
                :name="name"
                @change="$emit('change', $event.target.value)" />

            <div class="control__text radio__text">
                <label :for="item.value" class="control__label radio__label">{{ item.title }}</label>

                <div v-if="item.support || prompt" class="control__support radio__support">
                    <druk-support :hasNoIcon="true">{{ $t(item.support) || prompt }}</druk-support>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'form-radio-buttons-group',

        inject: ['$validator'],

        model: {
            prop: 'value',
            event: 'change',
        },

        props: {
            value: String,
            options: Array,
            name: String,
            prompt: String,

            isColumn: Boolean,
        },

        data() {
            return {
                option: null,
            };
        },

        computed: {
            radio: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.option = value;
                },
            },
        },
    };
</script>

<style lang="scss" scoped></style>
