import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faArrowDown,
    faChartLineUp,
    faCartShopping,
    faUser,
    faEnvelope,
    faMoneyBill,
    faClipboard,
    faPrint,
    faFolder,
    faBriefcase,
    faBook,
    faScrewdriverWrench,
    faUsers,
    faGear,
    faListRadio,
    faSitemap,
    faBusinessTime,
    faBolt,
    faRotate,
    faXmark,
    faSort,
    faArrowUp,
    faTrash,
    faPlusCircle,
    faCheck,
    faTriangleExclamation,
    faUpload,
    faDownload,
    faSortDown,
    faCalendarDay,
    faMagnifyingGlass,
    faPenToSquare,
    faEllipsisVertical,
    faBars,
    faCalculator,
    faGlobe,
    faBell,
    faArrowRightFromBracket,
    faArrowLeftLong,
    faEye,
    faCircleExclamation,
    faCopy,
    faIdCard,
    faPalette,
    faPencil,
    faPhone,
    faGlobeAmericas,
    faEnvelopesBulk,
    faArrowsUpDown,
    faChartLine,
    faCircleInfo,
    faPaperclip,
    faPlus,
    faClipboardList,
    faChartBar,
    faFileExport,
    faBarsFilter,
    faArrowRightLong,
    faArchive,
    faCalendarDays,
    faFileChartColumn,
    faRepeat,
    faFileImport,
    faHourglassStart,
    faMoneyCheckDollarPen,
    faMoneyCheckDollar,
    faFilter,
    faCircleCaretDown,
    faCircleDollar,
    faBan,
    faList,
    faPenNibSlash,
    faFilePlus,
    faSackDollar,
    faRotateRight,
    faCartCirclePlus,
    faUserPlus,
    faFloppyDisk,
    faArrowDownToBracket,
    faArrowUpFromBracket,
    faStar,
    faAngleDown,
    faMinus,
    faMessage,
    faStarOfLife,
    faBagShopping,
    faMoneyBillTransfer,
    faCircleQuestion,
    faSquareInfo,
    faFilePdf,
    faChartSimple,
    faAddressBook,
    faFileInvoiceDollar,
    faFile,
    faArrowUpRightFromSquare,
    faEmptySet,
    faArrowsFromLine,
    faArrowsToLine,
    faRotateLeft,
    faBrush,
    faCircleStar,
    faMessageLines,
    faComment,
    faPaperPlaneTop,
    faEllipsis,
    faCircleCheck,
    faCircleXmark,
    faSwatchbook,
    faLink,
    faFileArrowDown,
    faCalendar,
    faGears,
    faEyeSlash,
    faBackward,
    faScissors,
    faBooks,
    faGripDotsVertical,
    faMessagesQuestion,
    faTruck,
    faAnglesDown,
    faKeySkeleton,
    faForward,
    faBoxesPacking,
    faFunction,
    faCalculatorSimple,
    faChartMixed,
    faTrashArrowUp,
    faArrowLeft,
    faArrowRight,
    faCommand,
    faFilterSlash,
    faPenSlash,
    faCircleSmall,
    faQuestion,
    faArrowRotateRight,
    faListCheck,
    faMessages,
    faListTree,
    faUserCheck,
    faD,
    faSpinnerThird,
    faCaretDown,
    faGearCode,
    faSortUp,
    faImage,
    faPaintbrushPencil,
    faDrawSquare,
    faMapLocationDot,
    faAngleLeft,
    faAnglesLeft,
    faAngleRight,
    faAnglesRight,
    faUserSlash,
    faCartPlus,
    faEllipsisStrokeVertical,
    faEraser,
    faPen,
    faEuroSign,
    faDollarSign,
    faAbacus,
    faWandMagicSparkles,
    faSquareRootVariable,
    faShieldExclamation,
    faAddressCard,
    faBuildingUser,
    faPersonSimple,
    faNote,
    faCoins,
    faCartFlatbedBoxes,
    faCode,
    faFolderMagnifyingGlass,
    faWindow,
    faBrowser,
    faTruckFast,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
    faArrowDown,
    faChartLineUp,
    faCartShopping,
    faUser,
    faEnvelope,
    faMoneyBill,
    faClipboard,
    faPrint,
    faFolder,
    faBriefcase,
    faBook,
    faScrewdriverWrench,
    faUsers,
    faGear,
    faListRadio,
    faSitemap,
    faBusinessTime,
    faBolt,
    faRotate,
    faXmark,
    faSort,
    faArrowUp,
    faTrash,
    faPlusCircle,
    faCheck,
    faTriangleExclamation,
    faUpload,
    faDownload,
    faSortDown,
    faCalendarDay,
    faMagnifyingGlass,
    faPenToSquare,
    faEllipsisVertical,
    faBars,
    faCalculator,
    faGlobe,
    faBell,
    faArrowRightFromBracket,
    faArrowLeftLong,
    faEye,
    faCircleExclamation,
    faCopy,
    faIdCard,
    faPalette,
    faPencil,
    faPhone,
    faGlobeAmericas,
    faEnvelopesBulk,
    faArrowsUpDown,
    faChartLine,
    faCircleInfo,
    faPaperclip,
    faPlus,
    faClipboardList,
    faChartBar,
    faFileExport,
    faBarsFilter,
    faArrowRightLong,
    faArchive,
    faCalendarDays,
    faFileChartColumn,
    faRepeat,
    faFileImport,
    faHourglassStart,
    faMoneyCheckDollarPen,
    faMoneyCheckDollar,
    faFilter,
    faCircleCaretDown,
    faCircleDollar,
    faBan,
    faList,
    faPenNibSlash,
    faFilePlus,
    faSackDollar,
    faRotateRight,
    faCartCirclePlus,
    faUserPlus,
    faFloppyDisk,
    faArrowDownToBracket,
    faArrowUpFromBracket,
    faStar,
    faAngleDown,
    faMinus,
    faMessage,
    faStarOfLife,
    faBagShopping,
    faMoneyBillTransfer,
    faCircleQuestion,
    faSquareInfo,
    faFilePdf,
    faChartSimple,
    faAddressBook,
    faFileInvoiceDollar,
    faFile,
    faArrowUpRightFromSquare,
    faEmptySet,
    faArrowsFromLine,
    faArrowsToLine,
    faRotateLeft,
    faBrush,
    faCircleStar,
    faMessageLines,
    faComment,
    faPaperPlaneTop,
    faEllipsis,
    faCircleCheck,
    faCircleXmark,
    faSwatchbook,
    faLink,
    faFileArrowDown,
    faCalendar,
    faGears,
    faEyeSlash,
    faBackward,
    faScissors,
    faBooks,
    faGripDotsVertical,
    faMessagesQuestion,
    faTruck,
    faAnglesDown,
    faKeySkeleton,
    faForward,
    faBoxesPacking,
    faFunction,
    faCalculatorSimple,
    faChartMixed,
    faTrashArrowUp,
    faArrowLeft,
    faArrowRight,
    faCommand,
    faFilterSlash,
    faPenSlash,
    faCircleSmall,
    faQuestion,
    faArrowRotateRight,
    faListCheck,
    faMessages,
    faListTree,
    faUserCheck,
    faD,
    faSpinnerThird,
    faCaretDown,
    faGearCode,
    faSortUp,
    faImage,
    faPaintbrushPencil,
    faDrawSquare,
    faMapLocationDot,
    faAngleLeft,
    faAnglesLeft,
    faAngleRight,
    faAnglesRight,
    faUserSlash,
    faCartPlus,
    faEllipsisStrokeVertical,
    faEraser,
    faPen,
    faEuroSign,
    faDollarSign,
    faAbacus,
    faWandMagicSparkles,
    faSquareRootVariable,
    faShieldExclamation,
    faAddressCard,
    faBuildingUser,
    faPersonSimple,
    faNote,
    faCoins,
    faCartFlatbedBoxes,
    faCode,
    faFolderMagnifyingGlass,
    faWindow,
    faBrowser,
    faTruckFast,
);
