<template>
    <div
        class="search"
        :class="{
            'has-full': hasSelect,
            'has-active': hasActive,
            'has-focus': hasActive,
        }">
        <div class="search__area" ref="area">
            <form-input
                v-model="search"
                :class="{ 'has-loading': hasSoftLoading }"
                :name="name"
                :label="label"
                :surface="surface"
                :placeholder="placeholder"
                :hint="hint"
                :rules="rules"
                @onFocus="onFocus"
                @input="onSearch" />

            <div class="search__nav" v-if="!hasSelect && hasLoading">
                <druk-loader />
            </div>

            <div class="search__nav" v-else-if="hasSelect" @click="onClear">
                <font-awesome-icon icon="fa-regular fa-xmark" />
            </div>

            <div class="search__nav search__nav--search" v-else>
                <font-awesome-icon icon="fa-regular fa-magnifying-glass" />
            </div>
        </div>

        <div ref="cnt" class="search__cnt" :class="{ [`druk-l-surface-${surfaceVariant}`]: surfaceVariant, 'has-active': hasOptions }">
            <ul class="multiselect__content">
                <li class="multiselect__element" v-for="(option, index) in options" @click="onSelect(option)" :key="index">
                    <span class="multiselect__option">{{ option[customOption ? 'title' : option_label || 'title'] }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    const CancelToken = axios.CancelToken;
    let cancel;

    export default {
        name: 'form-search-async',
        inject: ['$validator'],

        props: {
            value: Object,
            options: Array,
            path: String,
            keySearch: String,
            rules: String,
            placeholder: String,
            label: String,
            option_label: String,
            name: String,
            extraOptions: Object,
            filter_key: String,
            customOption: Array,
            hint: [Object, String],
            side: String,
            hasSoftLoading: Boolean,
            isFilterType: Boolean,

            surface: {
                type: String,
                default: 'tint-pale',
            },

            surfaceVariant: {
                type: String,
                default: 'tint-bright',
            },
        },

        data() {
            return {
                search: this.value ? this.value[this.option_label || 'title'] : null,

                hasLoading: false,
                hasActive: false,

                timer: null,
                defer: 300,
            };
        },
        watch: {
            selectedValues() {
                this.search = this.value ? this.value[this.option_label || 'title'] : null;
            },
        },

        computed: {
            selectedValues() {
                return this.value;
            },

            hasSelect() {
                return !!this.value;
            },

            hasOptions() {
                return this.options ? this.options.length : false;
            },

            hasRequired() {
                if (this.rules && this.rules.indexOf('required') != -1) {
                    return true;
                }
                return false;
            },

            extraData() {
                if (!this.extraOptions) return '';

                let extra = '';
                $fn.forIn(this.extraOptions, (option, key) => {
                    extra += `&${key}=${option}`;
                });

                return extra;
            },
        },

        mounted() {
            let cnt = this.$refs['cnt'],
                area = this.$refs['area'];

            document.addEventListener('click', (e) => {
                let target = e.target;
                let its_cnt = target == cnt || cnt.contains(target);
                let its_area = target == area || area.contains(target);

                if (!its_cnt && !its_area) {
                    this.$emit('search', []);
                    if (!this.value) this.search = null;
                }
            });
        },

        methods: {
            onFocus(value) {
                this.hasActive = value;
            },

            onSearch(key) {
                clearTimeout(this.timer);

                if (!this.search || this.search.length < 3) return;

                this.timer = setTimeout(() => {
                    this.hasLoading = true;

                    if (cancel) cancel();

                    this.$axios
                        .get(`${this.path}?${this.keySearch}=${this.search}${this.extraData}`, {
                            cancelToken: new CancelToken(function executor(c) {
                                cancel = c;
                            }),
                        })
                        .then((resp) => {
                            this.$emit('search', {
                                list: resp.list,
                                query: this.search,
                                option: this.option_label ? this.option_label : null,
                                filter_key: this.filter_key ? this.filter_key : null,
                                customOption: this.customOption ? this.customOption : null,
                            });

                            this.hasLoading = false;
                        })
                        .catch((error) => {
                            this.$emit('input', null);
                            this.$emit('search', []);

                            this.hasLoading = false;

                            if (error?.response?.status === 403) {
                                this.$noty.error(this.$t('search.async.not'));
                                $VUE_APP.$bus.$emit('permission');
                            }
                        });
                }, this.defer);
            },

            onSelect(option) {
                this.search = option.name;

                this.$emit('input', option);
                this.$emit('search', []);
            },

            onClear() {
                this.search = null;

                this.$emit('input', null);
                this.$emit('search', []);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .search {
        position: relative;
        &__area {
            position: relative;
            z-index: 1;
        }
        &__nav {
            cursor: pointer;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 0;
            top: 0px;
            height: 44px;
            width: 44px;
            color: var(--druk-on-surface-variant);
            z-index: 2;
            &--search {
                pointer-events: none;
            }
        }
        &__cnt {
            position: absolute;
            visibility: hidden;
            top: 120%;
            max-height: 200px;
            margin-top: 2px;
            overflow: auto;
            left: 0;
            width: 100%;
            border-radius: 4px;
            color: var(--druk-on-surface-variant);
            box-shadow: var(--druk-elevation-2);
            z-index: 10;
            transition: opacity 0.2s ease-in-out;
            opacity: 0;
            &.has-active {
                opacity: 1;
                visibility: visible;
                top: 100%;
            }
        }
    }
</style>
