<template>
    <div class="vmodal">
        <div class="vmodal__container">
            <div class="vmodal__main">
                <div class="alert">
                    <div class="alert__text" v-html="text || $t('alert.question')"></div>

                    <div class="alert__footer">
                        <druk-button
                            class="druk-u-margin-right-8"
                            :type="'outlined'"
                            :icon="'xmark'"
                            :label="$t('alert.no')"
                            @click="onClose" />

                        <druk-button :icon="'check'" :label="$t('alert.yes')" @click="onVerify" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'modal-alert',

        props: {
            text: String,
            fnWait: Function,
        },

        methods: {
            onClose() {
                this.$emit('close');
            },

            onVerify() {
                this.fnWait();
                this.onClose();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .alert {
        &__text {
            margin-bottom: 16px;
            text-align: center;
            color: var(--druk-on-surface);
        }
        &__footer {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>
