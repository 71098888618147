import Vue from 'vue';

import axios from 'axios';

import wizard from './wizard';

const CancelToken = axios.CancelToken;
let cancel;

export default {
    namespaced: true,

    modules: {
        wizard,
    },

    state: {
        WINDING_DIRECTIONS: [
            {
                key: 'products.winding_direction.outside',
                title: null,
                value: 'outside',
            },
            {
                key: 'products.winding_direction.inside',
                title: null,
                value: 'inside',
            },
        ],

        OUTSIDE_WINDING_DIRECTION: 'inside',
        INSIDE_WINDING_DIRECTION: 'inside',

        CUSTOM_SIZE_ID: 'custom',

        list: [],
        rootList: [],

        listPagination: {},
        rootListPagination: {},

        item: {},
        rootItem: {},

        listFilter: {
            title: null,
            'catalog-id': null,
            calc_type: null,
            is_published: null,
            page: null,
            limit: 30,
            sort: 'new',
            direction: 'desc',
        },

        rootListFilter: {
            title: null,
            'catalog-id': null,
            calc_type: null,
            is_published: null,
            page: null,
            limit: 30,
            sort: 'new',
            direction: 'desc',
        },

        formData: {},

        customSizeData: {
            id: 'custom',
            key: 'product.custom_size',
            title: null,
            width: null,
            height: null,
        },

        parametersData: {
            core_diameters: null,
            default_indent: null,
            default_roll_diameter: null,
            max_indent: null,
            max_roll_diameter: null,
            min_indent: null,
            min_roll_diameter: null,
            roll_diameters: null,
        },

        loadingGetList: false,
        loadingGetRootList: false,
        loadingGetItem: false,
        loadingGetRootItem: false,
        loadingSaveItem: false,
        loadingAttachItem: false,
        loadingAttachList: false,
        loadingDeleteItem: false,
        loadingDeleteList: false,
        loadingExportList: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        rootListActiveFilter(state) {
            return Object.keys(state.rootListFilter)
                .filter((key) => state.rootListFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.rootListFilter[key]), resp), {});
        },

        kindsWithProductFormats(state) {
            return state.item.kinds?.filter((kind) => !!kind.details.filter((detail) => detail.use_product_format).length);
        },

        kindsWithStaticFormat(state) {
            return state.item.kinds?.filter(
                (kind) => !!kind.details.filter((detail) => !!(!detail.use_product_format && detail.format)).length,
            );
        },

        kindsDetails(state, getters) {
            return getters.kindsWithProductFormats.concat(getters.kindsWithStaticFormat);
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },

        hasEmptyFormData(state) {
            return !Object.keys(state.formData).length;
        },

        hasKindWithDetail(state) {
            return !!state.item.kinds.filter((kind) => !!kind.details.length).length;
        },

        hasOnlyOneDetailKinds(state) {
            let kindsWithDetails = state.item.kinds.filter((kind) => !!kind.details.length);
            return !!(kindsWithDetails.length === kindsWithDetails.filter((kind) => kind.details.length === 1).length);
        },

        hasKindWithProductFormats(state, getters) {
            return !!getters.kindsWithProductFormats?.length;
        },

        hasKindWithStaticFormat(state, getters) {
            return !!getters.kindsWithStaticFormat?.length;
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_ROOT_LIST(state, list) {
            state.rootList = list;
        },

        SET_ROOT_PAGINATION(state, pagination) {
            state.rootListPagination = pagination;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_ROOT_ITEM(state, item) {
            state.rootItem = item;
        },

        SET_FORM_DATA(state, formData) {
            Vue.set(state, 'formData', formData);
        },

        SET_FORM_DATA_PROP(state, { key, value }) {
            Vue.set(state.formData, key, value);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        SET_CUSTOM_SIZE_DATA(state, formData) {
            Object.assign(state.customSizeData, formData);
        },

        UPDATE_LIST_FILTER(state, newFilterObj) {
            $fn.forIn(newFilterObj, (val, key) => {
                Vue.set(state.listFilter, key, val);
            });

            Vue.set(state.listFilter, 'page', newFilterObj.page || 1);
        },

        UPDATE_FILTER_ROOT_LIST(state, newFilterObj) {
            $fn.forIn(newFilterObj, (val, key) => {
                Vue.set(state.rootListFilter, key, val);
            });

            Vue.set(state.rootListFilter, 'page', newFilterObj.page || 1);
        },

        UPDATE_FORM_DATA(state, formData) {
            state.formData = {
                ...state.formData,
                ...formData,
            };
        },

        UPDATE_PARAMETERS_DATA(state, parametersData) {
            state.parametersData = {
                ...state.parametersData,
                ...parametersData,
            };
        },

        RESET_ITEM(state) {
            state.item = {};
        },

        RESET_FORM_DATA(state) {
            state.formData = {};
        },

        RESET_STORE(state) {
            state.listFilter = {
                title: null,
                'catalog-id': null,
                calc_type: null,
                is_published: null,
                page: null,
                limit: 30,
                sort: 'new',
                direction: 'desc',
            };
        },

        RESET_ROOT_LIST(state) {
            state.rootListFilter = {
                title: null,
                'catalog-id': null,
                calc_type: null,
                is_published: null,
                page: null,
                limit: 30,
                sort: 'new',
                direction: 'desc',
            };
        },
    },

    actions: {
        GET_LIST: async ({ state, getters, rootGetters, commit }, filters = {}) => {
            try {
                if (cancel !== undefined) cancel();

                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = `/api/products`;
                if (rootGetters.hasTypographyAccess) {
                    path = `/api/typographies/${rootGetters.currentTypography.id}/products`;
                }

                let resp = await $axios.get(path, {
                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c;
                    }),

                    params: Object.keys(filters).length ? filters : getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                if (!e.response) return;

                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ROOT_LIST: async ({ state, getters, rootGetters, commit }, filters = {}) => {
            try {
                if (cancel !== undefined) {
                    cancel();
                }

                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetRootList',
                    status: true,
                });

                let path = `/api/products`;

                let resp = await $axios.get(path, {
                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c;
                    }),

                    params: Object.keys(filters).length
                        ? Object.assign(filters, getters.rootListActiveFilter)
                        : getters.rootListActiveFilter,
                });

                commit('SET_ROOT_LIST', resp.list);
                commit('SET_ROOT_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                if (!e.response) return;

                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetRootList',
                    status: false,
                });
            }
        },

        GET_ITEM: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let path = `/api/products/${id}`;
                if (rootGetters.hasTypographyAccess) {
                    path = `/api/typographies/${rootGetters[`currentTypography`].id}/products/${id}`;
                }

                let resp = await $axios.get(path);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        GET_ROOT_ITEM: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetRootItem',
                    status: true,
                });

                let path = `/api/products/${id}`;

                let resp = await $axios.get(path);

                commit('SET_ROOT_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetRootItem',
                    status: false,
                });
            }
        },

        SAVE_ITEM: async ({ state, commit, rootGetters, dispatch }, { formData, without_noty, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters[`currentTypography`].id}/products`
                    : `/api/products`;

                let resp = await $axios.post(path, formData, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                if (!without_fetch && formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        COPY_ITEM: async ({ state, rootGetters, commit, dispatch }, productId) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters[`currentTypography`].id}/products/copy/${productId}`
                    : `/api/products/copy/${productId}`;

                let resp = await $axios.post(path);

                dispatch('GET_LIST', productId);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        ATTACH_ITEM: async ({ state, commit, rootGetters, dispatch }, { productId, formData }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingAttachItem',
                    status: true,
                });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/products/attach/${productId}`,
                    formData,
                );

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingAttachItem',
                    status: false,
                });
            }
        },

        ATTACH_LIST: async ({ state, commit, rootGetters, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingAttachList',
                    status: true,
                });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/products/attach-multiple`,
                    formData,
                );

                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingAttachList',
                    status: false,
                });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, { id, parent_id, filters, without_fetch, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters[`currentTypography`].id}/products/${id}`
                    : `/api/products/${id}`;

                let resp = await $axios.delete(path, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                if (!without_fetch) {
                    parent_id || filters ? dispatch('GET_LIST', filters || { 'catalog-id': parent_id }) : dispatch('GET_LIST');
                }

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },

        DELETE_LIST: async ({ commit, rootGetters, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingDeleteList',
                    status: true,
                });

                let path = rootGetters.hasERPAccess
                    ? `/api/products/delete`
                    : `/api/typographies/${rootGetters[`currentTypography`].id}/products/delete`;

                let resp = await $axios.delete(path, {
                    data: formData,
                });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingDeleteList',
                    status: false,
                });
            }
        },

        EXPORT_LIST: async ({ state, commit, rootGetters, dispatch }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingExportList',
                    status: true,
                });

                let resp = await $axios.get(`/api/products/export`).then((resp) => {
                    const url = resp.link;
                    const link = document.createElement('a');
                    link.href = url;
                    link.click();

                    URL.revokeObjectURL(link.href);
                });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingExportList',
                    status: false,
                });
            }
        },
    },
};
