import Vue from './vue_base';

import FormInput from '@form/FormInput';
Vue.component('form-input', FormInput);

import FormSearchAsync from '@form/FormSearchAsync';
Vue.component('form-search-async', FormSearchAsync);

import FormMultipleAsync from '@form/FormMultipleAsync';
Vue.component('form-multiple-async', FormMultipleAsync);

import FormInputNum from '@form/FormInputNum';
Vue.component('form-input-num', FormInputNum);

import FormInputLogin from '@form/FormInputLogin';
Vue.component('form-input-login', FormInputLogin);

import FormTextarea from '@form/FormTextarea';
Vue.component('form-textarea', FormTextarea);

import FormEditor from '@form/FormEditor';
Vue.component('form-editor', FormEditor);

import FormSelect from '@form/FormSelect';
Vue.component('form-select', FormSelect);

import FormSelectGroup from '@form/FormSelectGroup';
Vue.component('form-select-group', FormSelectGroup);

import FormSelectOrAdd from '@form/FormSelectOrAdd';
Vue.component('form-select-or-add', FormSelectOrAdd);

import FormSelectTags from '@form/FormSelectTags';
Vue.component('form-select-tags', FormSelectTags);

import FormSwitch from '@form/FormSwitch';
Vue.component('form-switch', FormSwitch);

import FormCheckbox from '@form/FormCheckbox';
Vue.component('form-checkbox', FormCheckbox);

import FormCheckboxGroup from '@form/FormCheckboxGroup';
Vue.component('form-checkbox-group', FormCheckboxGroup);

import FormCheckGroup from '@form/FormCheckGroup';
Vue.component('form-check-group', FormCheckGroup);

import FormImgCheck from '@form/FormImgCheck';
Vue.component('form-img-check', FormImgCheck);

import FormRadioButtonsGroup from '@form/FormRadioButtonsGroup';
Vue.component('form-radio-buttons-group', FormRadioButtonsGroup);

import FormImg from '@form/FormImg';
Vue.component('form-img', FormImg);

import FormColorPicker from '@form/FormColorPicker';
Vue.component('form-color-picker', FormColorPicker);

import FormTimeRange from '@form/FormTimeRange';
Vue.component('form-time-range', FormTimeRange);

import FormFile from '@form/FormFile';
Vue.component('form-file', FormFile);

import FormDatePicker from '@form/FormDatePicker';
Vue.component('form-date-picker', FormDatePicker);

import FormInputTel from '@form/FormInputTel';
Vue.component('form-input-tel', FormInputTel);

import FormStatusPicker from '@form/FormStatusPicker';
Vue.component('form-status-picker', FormStatusPicker);

import FormLanguageTabs from '@form/FormLanguageTabs';
Vue.component('form-language-tabs', FormLanguageTabs);

import FormLimitedText from '@form/FormLimitedText';
Vue.component('form-limited-text', FormLimitedText);

import DraggableList from '@common/draggable/DraggableList';
Vue.component('draggable-list', DraggableList);

import VCard from '@common/components/VCard';
Vue.component('v-card', VCard);

import VTable from '@components/VTable';
Vue.component('v-table', VTable);

import ToolTip from '@common/components/ToolTip';
Vue.component('tool-tip', ToolTip);

import BreadCrumbs from '@common/components/BreadCrumbs';
Vue.component('bread-crumbs', BreadCrumbs);

import AdvancedFilter from '@common/components/AdvancedFilter';
Vue.component('advanced-filter', AdvancedFilter);

import ImgPreview from '@common/components/ImgPreview';
Vue.component('img-preview', ImgPreview);

import NavigationBar from '@common/components/NavigationBar';
Vue.component('navigation-bar', NavigationBar);

import WizardHeader from '@common/wizard/WizardHeader';
Vue.component('wizard-header', WizardHeader);

import WizardFooter from '@common/wizard/WizardFooter';
Vue.component('wizard-footer', WizardFooter);

import WizardStepsProgress from '@common/wizard/WizardStepsProgress';
Vue.component('wizard-steps-progress', WizardStepsProgress);

import CalcModes from '@common/calcModes/CalcModes';
Vue.component('calc-modes', CalcModes);

import RollModuleError from '@common/modules/RollModuleError';
Vue.component('roll-module-error', RollModuleError);

// DRUK design system
import DrukAvatar from '@common/components/druk/DrukAvatar';
Vue.component('druk-avatar', DrukAvatar);

import DrukBadge from '@common/components/druk/DrukBadge';
Vue.component('druk-badge', DrukBadge);

import DrukButton from '@common/components/druk/DrukButton';
Vue.component('druk-button', DrukButton);

import DrukCircularIndicator from '@common/components/druk/DrukCircularIndicator';
Vue.component('druk-circular-indicator', DrukCircularIndicator);

import DrukDialog from '@common/components/druk/DrukDialog';
Vue.component('druk-dialog', DrukDialog);

import DrukDialogOption from '@common/components/druk/DrukDialogOption';
Vue.component('druk-dialog-option', DrukDialogOption);

import DrukDivider from '@common/components/druk/DrukDivider';
Vue.component('druk-divider', DrukDivider);

import DrukDropdown from '@common/components/druk/DrukDropdown';
Vue.component('druk-dropdown', DrukDropdown);

import DrukFAB from '@common/components/druk/DrukFAB';
Vue.component('druk-fab', DrukFAB);

import DrukHint from '@common/components/druk/DrukHint';
Vue.component('druk-hint', DrukHint);

import DrukIcon from '@common/components/druk/DrukIcon';
Vue.component('druk-icon', DrukIcon);

import DrukIconButton from '@common/components/druk/DrukIconButton';
Vue.component('druk-icon-button', DrukIconButton);

import DrukIconToggleButton from '@common/components/druk/DrukIconToggleButton';
Vue.component('druk-icon-toggle-button', DrukIconToggleButton);

import DrukLink from '@common/components/druk/DrukLink';
Vue.component('druk-link', DrukLink);

import DrukLoader from '@common/components/druk/DrukLoader';
Vue.component('druk-loader', DrukLoader);

import DrukLinearIndicator from '@common/components/druk/DrukLinearIndicator';
Vue.component('druk-linear-indicator', DrukLinearIndicator);

import DrukSideSheet from '@common/components/druk/DrukSideSheet';
Vue.component('druk-side-sheet', DrukSideSheet);

import DrukSupport from '@common/components/druk/DrukSupport';
Vue.component('druk-support', DrukSupport);

import DrukTabs from '@common/components/druk/DrukTabs';
Vue.component('druk-tabs', DrukTabs);
