var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"selector selector--select",class:{
            'has-focus': _vm.hasActive,
            'has-full': _vm.hasFull,
            'has-error': _vm.errors.has(_vm.name),
            'has-loading': _vm.loader,
            'druk-is-disabled': _vm.options.length <= 1 && _vm.notEmpty,
            'selector--multiple': _vm.multiple,
        }},[_c('div',{staticClass:"selector__label",class:{ [`druk-l-surface-${_vm.surface}`]: _vm.surface },on:{"click":_vm.onToggle}},[_c('span',[_c('label',[_vm._v(_vm._s(_vm.label))]),(_vm.hasRequired)?_c('i',[_vm._v("*")]):_vm._e()]),(_vm.hint)?_c('druk-hint',{attrs:{"tooltip":{ text: _vm.hint.text || _vm.hint, from: 'top', maxWidth: _vm.hint.maxWidth, isNotCollapse: _vm.hint.isNotCollapse },"icon":{ name: 'circle-info', size: 'xs', color: _vm.hasActive ? 'primary' : 'outline' }}}):_vm._e()],1),_c('div',{staticClass:"selector__main"},[_c('multiselect',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.rules || ''),expression:"rules || ''"}],ref:"multiselect",staticClass:"selector__area",style:({ [`--druk-content-background`]: _vm.contentBackground }),attrs:{"data-vv-name":_vm.name,"data-vv-as":_vm.label,"options":_vm.splittedOptions,"multiple":_vm.multiple || false,"close-on-select":_vm.multiple ? false : true,"label":_vm.value && _vm.value[`parsed_${_vm.option_label || 'title'}`] ? `parsed_${_vm.option_label || 'title'}` : _vm.option_label || 'title',"placeholder":_vm.placeholder || '',"groupLabel":_vm.isActiveSeparate ? 'title' : _vm.groupLabel || null,"groupValues":_vm.isActiveSeparate ? 'options' : _vm.groupValues || null,"tagPlaceholder":'',"selectLabel":'',"deselectLabel":'',"selectedLabel":'',"hideSelected":_vm.notEmpty,"searchable":!_vm.notEmpty,"track-by":_vm.option_id || 'id'},on:{"select":function($event){return _vm.onSelect($event)},"open":function($event){_vm.hasActive = true},"close":function($event){_vm.hasActive = false}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{class:{
                            '_flex _flex_direction_column': props.option.prompt || props.option.has_divider,
                        }},[(props.option.$isLabel)?_c('span',[_vm._v(_vm._s(props.option.$groupLabel))]):[_c('span',[_vm._v(_vm._s(props.option[`parsed_${[_vm.option_label || 'title']}`] || props.option[_vm.option_label || 'title'] || `(${_vm.$t('common.without_name')})`))]),(props.option.prompt)?_c('span',{staticClass:"multiselect__prompt"},[_vm._v(" "+_vm._s(`${_vm.$t(props.option.promptPayload)}: ${props.option[props.option.prompt].title}`)+" ")]):_vm._e(),(props.option.has_divider)?_c('span',{staticClass:"multiselect__divider"}):_vm._e()]],2)]}},{key:"singleLabel",fn:function(props){return (!_vm.multiple)?[_vm._v(" "+_vm._s(props.option[`parsed_${[_vm.option_label || 'title']}`] || props.option[_vm.option_label || 'title'] || `(${_vm.$t('common.without_name')})`)+" ")]:undefined}}],null,true),model:{value:(_vm.selectedValues),callback:function ($$v) {_vm.selectedValues=$$v},expression:"selectedValues"}},[_c('template',{slot:"noResult"},[_vm._v(_vm._s(_vm.$t('knowledge.results.no_data')))])],2),(_vm.multiple && _vm.selectedValues)?_c('div',{staticClass:"multiselect__single"},[_vm._v(" "+_vm._s(_vm.customMultipleLabel || `${_vm.$t('filter.values')}: ${_vm.selectedValues.length}`)+" ")]):_vm._e(),(!_vm.hasFull || !_vm.isFilterType || (_vm.notEmpty && _vm.isFilterType) || _vm.isSorting)?_c('div',{staticClass:"selector__select",class:{ 'has-active': _vm.hasFull || _vm.hasActive },on:{"click":_vm.onToggle}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-angle-down"}})],1):_vm._e(),(_vm.isFilterType && _vm.hasFull && !_vm.notEmpty)?_c('div',{staticClass:"selector__clear",on:{"click":_vm.onClear}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-xmark"}})],1):_vm._e()],1),(_vm.errors.has(_vm.name))?_c('div',{staticClass:"selector__error"},[_c('span',{class:{ [`druk-l-surface-${_vm.surface}`]: _vm.surface }},[_vm._v(_vm._s(_vm.errorText || _vm.errors.first(_vm.name)))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }