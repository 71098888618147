<template>
    <div class="switch" :class="{ 'druk-is-checked': checked }">
        <label class="switch__wrap" :class="{ 'druk-is-reverse': isReverse }">
            <input
                type="checkbox"
                class="switch__area"
                :name="name"
                :checked="checked"
                @change="$emit('change', +$event.target.checked)"
                v-validate="rules || ''" />
            <i class="switch__handle"></i>

            <span class="switch__label">
                <span class="switch__label-text druk-u-text-label-lg">{{ label }}</span>

                <div v-if="hint" class="switch__label-addl">
                    <druk-hint :tooltip="hint" />
                </div>

                <div v-if="support" class="switch__label-support">
                    <druk-support :hasNoIcon="true">{{ support }}</druk-support>
                </div>
            </span>
        </label>

        <!-- <span v-if="errors.has(name)" class="switch__error">{{errorText || errors.first(name) }}</span> -->
    </div>
</template>

<script>
    export default {
        name: 'form-switch',

        inject: ['$validator'],

        model: {
            prop: 'checked',
            event: 'change',
        },

        props: {
            checked: {
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            type: String,
            label: String,
            hint: [String, Object],
            support: String,
            rules: String,
            errorText: String,
            isReverse: Boolean,
        },
    };
</script>

<style lang="scss" scoped></style>
