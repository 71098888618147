export default {
    'login.title': 'Вход в систему',
    'typog.table.title': 'Подключенная типография',
    'trans.title': 'Статические переводы',
    'trans.table.module': 'Модуль',
    'trans.table.text': 'Текст',
    'trans.table.scope': 'Scope',
    'trans.save.title': 'статический перевод',
    'partner.name': 'Партнёрка',
    'partner.order': 'Заявка от',
    'partner.title': 'Заявки на партнерство',
    'partner.create': 'Создать заявку',
    'mail.title': 'Настройки шаблонов писем',
    'mail.save.title': 'Редактировать шаблоны писем',
    'mail.form.subject': 'Тип письма',
    'mail.form.slogan': 'Слоган',
    'mail.form.body': 'Контент письма',
    'mail.form.footer': 'Футер письма',
    'mail.form.email': 'Email для теста',
    'mail.form.test': 'Протестировать письмо',
    'partners.title': 'Контакты',
    'partner.table.country': 'Страна',
    'partner.table.city': 'Город',
    'partner.table.code': 'Код ЕГРПОУ',
    'partner.table.workers': 'Кол-во сотрудников',
    'partner.table.web': 'Сайт типографии',
    'partner.table.msg': 'Сообщение',
    'partner.catalog': 'Ассортимент типографии',
    'partner.modules': 'Подключаемые модули',
    'partner.dop.data': 'Дополнительные данные',
    'partner.form.code': 'ЕГРПОУ',
    'partner.form.workers': 'Кол-во сотрудников',
    'partner.form.source': 'Источник',
    'partner.range': 'Ассортимент продукции',
    'custom.use.format': 'Использовать формат продукта',
    'custom.pay.count': 'К оплате за тираж',
    'partners.main.data': 'Основные данные',
    'partners.contact.sm': 'контакт',
    'typog.edit.title': 'Редактировать типографию',
    'material.in.pack': 'в упаковке',
    'material.item.in.pack': 'в упаковке) / Сумма за упаковку',
    'material.in.pack.alt': 'упаковки(-вок)',
    'equip.warning.type1': 'Для идентификации выбранной строки оборудования, он должен иметь хотя бы одну характеристику',
    'equip.warning.type2': 'Пожалуйста, задайте значения характеристикам',
    'equip.warning.type3': 'Укажите себестоимость для идентификации строки оборудования',
    'equip.warning.type4': 'Добавьте расчёт цены клика',
    'comment.manager': 'Комментарий менеджера',
    'partners.module': 'Модули',
    'partners.specialization': 'Специализация',
    'partners.date': 'Дата заявки',
    'partners.contact': 'Контакт',
    'material.tr.warning': 'Для идентификации вида материала, он должен иметь хотя бы одну характеристику',
    '': '',
    '': '',

    //BALANCE------------------/
    'balance.title': 'Баланс',
    'balance.tab1': 'Баланс на счетах',
    'balance.tab2': 'Транзакции',
    'balance.btn.start': 'Запуск смены',
    'balance.table.creator': 'Плательщик/Клиент',
    'balance.table.account': 'Счет',
    'balance.table.uuid': 'Фискализация чека',
    'balance.table.type': 'Тип транзакции',

    //ACCAUNT---------------/
    'account.btn.transfer': 'Перевод между счетами',
    'account.btn.add': 'Добавить счет',
    'account.save.title': 'счет',
    'account.form.owner': 'Владелец',
    'account.form.type': 'Тип счета',
    'account.form.card': 'Номер карты',
    'account.form.usreou': 'Код ЕГРПОУ',
    'account.form.bank': 'Банк',
    'account.form.bill': 'Счёт',
    'account.form.mfo': 'МФО',
    'account.form.address': 'Адрес',
    'account.form.phone': 'Телефон/Факс',
    'account.form.category': 'Категория налога',
    'account.transf.title': 'Перевод средств между счетами',
    'account.form.from': 'С счета',
    'account.access': 'Доступно',
    'account.form.amount': 'перевести суму',
    'account.form.account.to': 'На счет',
    'account.btn.send': 'Перевести',
    'account.curse': 'По курсу на момент тразакции:',

    //SEO---------------------------/
    'seo.title': 'SEO настройки',
    'seo.btn.rob': 'Редактировать robots',
    'seo.btn.protector': 'Редактировать protector',
    'seo.table.type': 'Тип шаблона',
    'seo.save.title': 'Редактировать SEO данные',
    'seo.save.form.meta.title': 'SEO Заголовок',
    'seo.save.form.meta.description': 'SEO Описание',
    'seo.save.bnt.area': 'Поле для вставки',
    'seo.save.global': 'Глобальные переменные',
    'seo.protect.title': 'Редактировать protector',
    'seo.protect.form.text': 'Text',
    'seo.robots.title': 'Редактировать robots',
    'seo.robots.form.text': 'Robots',

    //CONTACT----------------------------/
    'contact.title': 'Клиент',
    'contact.title.multi': 'Клиенты',
    'contact.not.verify': 'Не верифицирован',
    'contact.card.close': 'Свернуть карточку клиента',
    'contact.card.open': 'Развернуть карточку клиента',
    'contact.form.email.dop': 'Email (Дополнительное поле)',
    'contact.manager': 'Ответственный менеджер',
    'contact.address.last': 'Последний аддресс доставки',
    'contact.address.sm': 'Аддресс доставки',
    'contact.close': 'Спрятать',
    'contact.show': 'Показать',
    'contact.address.dop.title': 'все адресса доставки',
    'contact.order': 'Заказы',
    'contact.draft': 'Заявки',
    'contact.finance': 'Транзакции',
    'contact.import.loading': 'Идет процесс импорта',
    'contact.btn.add': 'Добавить клиента',
    'contact.balance.sum.in': 'Внести суму',
    'contact.balance.title': 'Пополнить баланс наличкой',
    'contact.balance.currency': 'Валюта',
    'contact.balance.replenish': 'Пополнить',
    'contact.import.title': 'Импорт клиентов',
    'contact.import.file': 'Файл со списком клиентов',
    'contact.import.warning1': '*принимаються файлы форматов: xlsx',
    'contact.import.warning2': '*Если иппортируеться поле баланс, валюта указываться в',
    'contact.btn.import': 'Импортировать',
    'contact.msg.loading': 'Процесс импорта может занять несколько минут.',
    'contact.discount.info': 'Введите в поле скидку клиента. Максимальная скидка составляет 20%',
    'contact.finance.operation': 'Фінансові операції',
    'contact.verify.not': 'Не верификован',
    'contact.save.title': 'клиента',

    //EMPLOYEES-----------------------/
    'empl.title': 'Список работников',
    'empl.table.fio': 'ФИО сотрудника',
    'empl.table.role': 'Роль',
    'empl.table.contact': 'Контактные данные',
    'empl.form.permission': 'Разделы',
    'empl.form.typog': 'Типография',
    'empl.item.title': 'Работник',
    'empl.table.name': 'Фамилия/Имя:',
    'empl.item.open': 'Открытые доступы роли',
    'empl.item.edit': 'Режим редактирования',
    'empl.not.edit': '* Вы неможете редактировать свою же роль.',
    'empl.table.emploeers': 'Работники',
    'empl.logs.title': 'История логов работника',
    'empl.logs.not': 'Еще нет логов...',
    'empl.role.dashboard': 'Дэшборды',
    'empl.role.dashboard.single': 'Дэшборд',
    'empl.role.dashboard.info': '* Сводный отчет, формируется на основе доступных разделов',
    'empl.role.title.save': 'Изменения роли',
    'empl.role.info': 'Вы внесли изменения в существующую роль',
    'empl.role.picked': 'Применить изменения к существующей роли. ',
    'empl.role.pecked.create': 'Создать новую роль с внесенными изменениями',
    'empl.form.key.new': 'Ключ новой роли',
    'empl.form.key': 'Новая роль',
    'empl.form.key.info': 'Впишите название новой роли',
    'empl.change.title': 'Назначить новую роль',
    'empl.change.info.true': '*Вы назначаете роль для нового сотрудника',
    'empl.change.info.false': '*Вы можете назначить другую роль для сотрудника',
    'empl.change.role': 'Роль сотрудника',
    'empl.change.': '',
    'empl.save.empl': 'сотрудника',
    'empl.form.phone': 'Телефон',
    'empl.pass': 'Пароль',
    'empl.pass.new': 'Новый пароль',
    'empl.active': 'Активен',
    'empl.pass.copy': 'Пароль скопирован',
    'empl.role.use': 'Роль была назначена',
    'empl.role.druk': 'Роль для DRUK.UA',
    'empl.role.typog': 'Роль для типографии',

    //update
    'typog.logo.info': '*Логотип будет отображаться на Вашем сайте и в письмах от Вашей типографии',
    'typog.color.info': '*Выбранный цвет будет применен в оформлении Вашего сайта и в интерфейсе системы управления типографией.',

    //update v1.2 // WT-707
    'maket.face': 'лицевая',
    'maket.back': 'обратная',
    'maket.page.sm': 'стр',
    'maket.prev.view': 'Смотреть превью',
    'maket.tech': 'Тех. требования',
    'maket.generate': 'Авто генерация',
    'maket.to.task': 'Относиться к задаче',
    'maket.empty':
        'Нет ни одной детали для которой нужен макет. <br> *Нужно изменить настройки деталей этого продукта, для возможности загрузки макета.',
    'maket.loading': 'Загрузка макетов',
    'maket.noty.error':
        'Возникла проблема на стороне сервиса обработки файлов. Загрузите файл вручную или обратитесь к техническому специалисту.',
    'maket.close': 'Звернути завантаження',
    'maket.file.loaded': 'Завантаження файлів',
    'maket.open': 'Розгорнути',
    'maket.status.end.check': 'Завантаження та перевірка завершено',
    'maket.status.end': 'Завантаження завершено',
    'maket.cancel': 'Відмінити завантаження',
    'maket.close.sm': 'Звернути',

    //WT-694
    'typog.module.empty': 'Нет подключенных модулей',

    //WT-569
    'css.editor.title': 'CSS редактор',
    'css.editor.info':
        'Для редактирования стилей на сайте нужно вписать существующий класс и, используя пунктуацию, описать свои правила. После сохранения, внесенные изменения появяться на Вашем сайте ',

    // WT-680
    'pages.index.common_pages': 'Стандартні сторінки',
    'page.index.is_published': 'Опублікована',
    'page.index.is_not_published': 'Неопублікована',
    'page.index.is_typography_page': 'Власна сторінка',
    'pages.modal_enabling.title': 'Підключення стандартних сторінок',
    'pages.form_save.prompt':
        "'Зверніть увагу, що для власних сторінок типографії посилання починатиметься з тегом 'p-'. Ви можете видалити його, але система все-одно збереже сторінку коректно ;')",
    'pages.form_preview.title': 'Оберіть шаблон сторінки',

    // Metrics
    'metrics.gramms': 'г',

    // ModalTechMap
    'modal.tech.map.delivery_type': 'Тип доставки',
    'modal.tech.map.address': 'Адреса',
    'modal.tech.map.mockups': 'Макети',

    //WT-719
    'work.area': 'Робоча область',

    //WT-756
    'product.tech.title': 'Технические требования',
    'product.tech.info':
        'Вы можете добавить ссылку на страницу с описанием как подготовить макет для печати. Эта информация будет доступна клиентам на странице загрузки макета',

    //V1.3
    //WT-791
    'mgs.empty': 'Нет новых сообщений...',
    'tech.information': 'Технічна інформація',
    'currency.change': 'Валюта изменена',
    'currency.controll.title': 'Управление валютами',
    'currency.controll.info': 'Впишите курс валют или установите курс банка',
    'techin.check': 'Відладка',
    'techin.device': 'Інформація про девайс',
    'techin.screen': 'Інформація про екран',
    'menu.command': 'Команда',
    'menu.product': 'Продукция',
    'menu.librery': 'Библиотека',
    'menu.typog': 'Типографии',
    'menu.website': 'Упр. сайтом',
    'menu.task': 'Задачи',
    'menu.stock': 'Склад',
    'menu.employyer': 'Сотрудники',
    'menu.dash': 'Дашборд',
    'menu.orders': 'Заказы',
    'menu.task.all': 'Все задачи',
    'menu.task.pre': 'Пре-пресс',
    'menu.task.print': 'Печать',
    'menu.task.post': 'Пост-пресс',
    'menu.task.logistic': 'Логистика',
    'menu.balance': 'Баланс',
    'menu.romutations': 'Комуникации',
    'menu.category': 'Категории',
    'menu.product.alt': 'Товары',
    'menu.employyers.alt': 'Работники',
    'menu.clients': 'Клиенты',
    'menu.typography': 'Типографии',
    'menu.typography.settings': 'Наст. Типографии',
    'menu.partners': 'Партнёрка',
    'menu.translates': 'Переводы',
    'menu.pages': 'Стат. страницы',
    'menu.blogs': 'Блог',
    'menu.seo': 'SEO',
    'menu.mainTemplates': 'Шаблоны писем',
    'menu.css': 'CSS редактор',
    'menu.works': 'Работы',
    'menu.contractors': 'Подрядчики',
    'menu.algorithms': 'Алгоритмы',
    'menu.details': 'Детали',
    'menu.materials': 'Материалы',
    'menu.equipments': 'Оборудование',
    'menu.formats': 'Форматы',
    'menu.roles': 'Роли',
    'menu.clientModules': 'Модули типографии',
    'menu.translateScopes': 'Модули переводов',
    'menu.settings': 'Настройки ТХ',
    'menu.params': 'Наз. характеристик',
    'menu.availability': 'Наличие (остатки)',
    'menu.inventory': 'Инвентаризация',
    'menu.stocklog': 'Приход и расход',
    'menu.supplier': 'Поставщики',
    'menu.supply': 'Поставки',

    'adv.filter': 'Загрузка фильтров',
    'alert.yes': 'Да',
    'alert.no': 'Нет',
    'file.loading': 'Завантаження та перевірка файлу...',
    'file.choose': 'Выберите файл',
    'run.not': 'не начато',
    'run.hot': 'срочных',
    'pay.status.true': 'Оплачено',
    'pay.status.false': 'Не оплачено',
    'filter.sort': 'Сортировка',
    'order.counter': 'Кол-во заказов',
    'sort.to.old': 'От новых к старым',
    'xaxis.status.new': 'Новых',
    'xaxis.status.end': 'Завершено',
    'xaxis.status.accept': 'Принято',
    'xaxis.status.cancel': 'Отменено',
    'status.accept': 'Принять',
    'status.cancel': 'Отменить',
    'picker.label.range': 'Custom Range',
    'admin.week.day1': 'Нд',
    'admin.week.day2': 'Пн',
    'admin.week.day3': 'Вт',
    'admin.week.day4': 'Ср',
    'admin.week.day5': 'Чт',
    'admin.week.day6': 'Пт',
    'admin.week.day7': 'Сб',
    'month.type1': 'Январь',
    'month.type2': 'Февраль',
    'month.type3': 'Март',
    'month.type4': 'Апрель',
    'month.type5': 'Май',
    'month.type6': 'Июнь',
    'month.type7': 'Июль',
    'month.type8': 'Август',
    'month.type9': 'Сентябрь',
    'month.type10': 'Октябрь',
    'month.type11': 'Ноябрь',
    'month.type12': 'Декабрь',
    'editor.p': 'Параграф',
    'editor.h1': 'Заголовок 1',
    'editor.h2': 'Заголовок 2',
    'editor.h3': 'Заголовок 3',
    'editor.h4': 'Заголовок 4',
    'search.async.not': 'У вас нет прав на просмотр запрашиваемой информации!',
    'select.paceholder.create': 'Нажмите чтобы создать',
    'select.paceholder.select': 'Нажмите чтобы выбрать',
    'time.range.start': 'Начало',
    'time.range.for': 'дo',
    'time.range.end': 'Конец',
    'editor.code': 'Исходный код',
    'enter.login': 'Вход в систему',
    'managers.not': 'Без менеджера',
    'employ.type.range': 'Диапазон',
    'employ.type.bize': 'Кривая Безье',
    'employ.type.formula': 'Формула',
    'error.tech': 'Технічне обслуговування',
    'alert.question': 'Ви впевнені?',

    //WT-793
    'login.main.title': 'DRUK.UA - система <br> автоматизации процессов  типографии',
    'login.item1': 'Стандартизация процессов',
    'login.item2': 'Автоматизация учета входящих заявок',
    'login.item3': 'Система управления ресурсами',
    'login.item4': 'Непрерывный процесс лидогенерации',
    'login.item5': 'Автоматизация первичной бухгалтерии',

    // WT-635
    'order.invoice_generation': 'Згенерувати рахунок на оплату',

    // WT-782
    'basket.item.has_errors': 'З макетом виникли проблеми',
    'admin.basket.item.has_errors.more_details': 'Дізнатися більше',
    'admin.edit_mockups.report_modal.title': 'Результати автоматичної перевірки',
    'admin.edit_mockups.report_modal.area': 'Безпечна область для макету',
    'admin.edit_mockups.report_modal.param': 'Параметр',
    'admin.edit_mockups.report_modal.requires': 'Вимагається',
    'admin.edit_mockups.report_modal.file': 'Ваш файл',
    'admin.edit_mockups.report_modal.fileSize': 'Розмір файлу',
    'admin.edit_mockups.report_modal.format': 'Формат файлу',
    'admin.edit_mockups.report_modal.colorSpace': 'Кольорова модель',
    'admin.edit_mockups.report_modal.geometry': 'Розміри макету',
    'admin.edit_mockups.report_modal.dpi': 'Розширення DPI',
    'admin.edit_mockups.report_modal.lessThanOrEqual': 'Не більше',
    'admin.edit_mockups.report_modal.greaterThanOrEqual': 'Не менше',
    'admin.edit_mockups.report_modal.mb': 'Мб',
    'admin.edit_mockups.report_modal.vector': 'Векторний файл',

    // WT-864
    'pages.modalSave.input.image': 'Головне зображення',
    'pages.modalSave.input.image_prompt': 'Рекомендований розмір: 500х500px',
    'common.block': 'Елемент сторінки',

    // WT-863
    'librery.works.components.modalSaveFormula_box_title_formula':
        'Формула будується для розрахунку ціни залежно від обраних змінних та функцій-дій. Наприклад:',
    'librery.works.components.modalSaveFormula_box_title_time_formula':
        'Формула будується для розрахунку часу залежно від обраних змінних та функцій-дій. Наприклад:',
    'librery.works.components.modalSaveFormula_box_title_weight_formula':
        'Формула будується для розрахунку ваги залежно від обраних змінних та функцій-дій. Наприклад:',
    'librery.works.components.modalSaveFormula_formula_preview':
        "<span class='has-functions'>округлвгору</span><span>(</span><span>(</span><span class='has-global'>[к-сть метрів матеріалу]</span><span> + </span><span>1</span><span>)</span><span> * </span><span>(</span><span class='has-equipment'>[собівартість кліку]</span><span> + </span><span class='has-material'>[ціна матеріалу]</span><span> + </span><span class='has-equipment'>[Амортизація обладнання]</span><span>)</span><span> * </span><span>2.35</span><span> + </span><span>3.030303</span><span>)</span><span> * </span><span>(</span><span>1</span><span> + </span><span class='has-equipment'>[ціна кліку]</span><span>)</span>",
    'librery.works.components.modalSaveFormula_formulaSection_title_formula': 'Ваша формула розрахунку ціни',
    'librery.works.components.modalSaveFormula_formulaSection_title_time_formula': 'Ваша формула розрахунку часу',
    'librery.works.components.modalSaveFormula_formulaSection_title_weight_formula': 'Ваша формула розрахунку ваги',
    'librery.works.components.modalSaveFormula_formulaSection_prompt':
        'Для створення формули виберіть змінні та функції праворуч або почніть писати в полі',

    // WT-851
    'admin.roll.winding_type': 'Спосіб намотування',
    'admin.common.preview': "Прев'ю",
    'modal.tech.map.winding_type': 'Спосіб намот.',

    // Bitrix Upd 23_1_23
    'typog.form.stages.agreement': 'Статус: Узгодження ТЗ',
    'typog.form.stages.file_checking': 'Статус: Перевірка файлу',
    'typog.form.stages.payment_waiting': 'Статус: Очікування оплати',
    'typog.form.stages.in_progress': 'Статус: Замовлення у роботі',
    'typog.form.stages.in_production': 'Статус: Замовлення у виробництві',
    'typog.form.stages.production_s': 'Статус: Виробництво',
    'typog.form.stages.production_is_done': 'Статус: Виробництво завершене',
    'typog.form.stages.shipment': 'Статус: Відвантаження',
    'typog.form.stages.order_canceling': 'Статус: Скасування замовлення',
    'typog.form.stages.control': 'Статус: Контроль',
    'typog.form.stages.bargain_is_done': 'Статус: Завершити угоду',
    'typog.form.stages.bargain_losed': 'Статус: Угода провалена',
    'typog.form.stages.another_bargain': 'Статус: Інша угода',
    'typog.form.stages.failure_analize': 'Статус: Аналіз причин провалу',

    // WT-898:
    'librery.works.components.formSave.params_title': 'Параметри роботи',
    'librery.works.components.formSave.params_prompt': 'Впливають на розрахунок часу, обмеження при якому робота може бути доступна',
    'librery.works.components.formSave.formulas_title': 'Формули розрахунку',
    'librery.works.components.formSave.radio_title': 'Тип роботи',
    'librery.materials.MaterialViewSave.title': 'Вид матеріалу',
    'common.params': 'Параметри',
    'common.main_data': 'Основні дані',
    'common.clear': 'Очистити',

    // WT-867:
    'languages.form_title': 'Доступні мови',
    'createOrder.common_category': 'Всі продукти',

    // WT-928:
    'params.title.material.options': 'Значення характеристик матеріалів',
    'params.title.equipment.options': 'Значення характеристик обладнання',
    'params.options.type': 'Тип',
    'params.options.param': 'Параметр',

    // WT-941:
    'common.pagination_from': 'з',

    // WT-940:
    'common.table.empty': 'Список пустий...',

    // WT-946:
    'material.count': 'К-сть',

    // WT-936:
    'formula.prompt_empty': 'Почніть писати тут...',

    // WT-898:
    'librery.works.components.formSave.params_title': 'Параметри роботи',
    'librery.works.components.formSave.params_prompt': 'Впливають на розрахунок часу, обмеження при якому робота може бути доступна',
    'librery.works.components.formSave.formulas_title': 'Формули розрахунку',
    'librery.works.components.formSave.radio_title': 'Тип роботи',
    'librery.materials.MaterialViewSave.title': 'Вид матеріалу',
    'common.params': 'Параметри',
    'common.main_data': 'Основні дані',
    'librery.equipments.EquipmentsViewSave.title': 'Вид устаткування',
    'common.clear': 'Очистити',
    'librery.works.components.formSave.group': 'Група',

    // WT-969:
    'common.read_more': 'Читати більше',
    'common.read_more_close': 'Згорнути',

    // WT-960:
    'contr.import_form.file_prompt': 'Рекомендований формат файлу Microsoft Excel (XLSX)',

    // WT-978:
    'views.logs.show_details': 'Показати деталі',
    'views.logs.hide_details': 'Приховати деталі',
    'views.calc.general_result': 'Загальний результат',
    'metrics.seconds': 'с',
    'views.logs.show_mainDetails': 'Подробиці',
    'views.logs.hide_mainDetails': 'Згорнути',
    'views.logs.result_title': 'Результат розрахунків',

    // WT-985:
    'linked_task.modal.messages': 'Коментарі',
    'linked_task.modal.messages__prompt': 'Коментарі до замовлення відсутні',

    // WT-934:
    'common.hours': 'год',
    'common.mins': 'хв',
    'common.secs': 'с',

    // WT-934:
    'order.item.is_new_deadline': 'Орієнтовна дата готовності',
    'order.item.is_production_deadline': 'Дата видачі',
    'task.table.time': 'Час в-цтва товару',

    // WT-984:
    'form.search': 'Пошук',
    'common.all': 'Усі',
    'orders.search_prompt': 'Пошук відбувається за назвами, номерами та коментарями до замовлень і товарів',

    // WT-939:
    'common.noty_post_success': 'Операція пройшла успішно',
    'common.noty_delete_success': 'Успішно видалено',

    // WT-1011:
    'common.transaction_types.bitrix': 'Оплата bitrix',
    'common.transaction_types.order': 'Оплата замовлення з балансу клієнта',
    'common.transaction_types.cash': 'Ручне поповнення балансу клієнта',
    'common.transaction_types.transfer': 'Переказ між рахунками',
    'common.transaction_types.deposit': 'Ручне поповнення рахунку',
    'common.transaction_types.withdrawal': 'Виведення грошей',
    'common.transaction_types.card_payment': 'Платіжна система',

    // WT-947:
    'sort.to_old': 'Від нових до старих',
    'sort.to_new': 'Від старих до нових',
    'sort.by_alpha': 'За алфавітом',
    'grouping.default': 'Без групування',
    'grouping.product': 'Групування за товарами',
    'grouping.order': 'Групування за замовленнями',
    'grouping.operation': 'Групування за типом робіт',
    'common.partners_products.sheet_offset_products': 'Офсетна продукція в аркушах',
    'common.partners_products.pad_printing': 'Тамподрук',
    'common.partners_products.sheet_fed_digital_printing': 'Цифровий друк в аркушах',
    'common.partners_products.souvenir_products': 'Сувенірна продукція',
    'common.partners_products.roll_flexo': 'Рулонна флексо',
    'common.partners_products.label_and_packing': 'Етикетка та паковання',
    'common.partners_products.roll_digital_printing': 'Рулонний цифровий друк',
    'common.partners_products.books_and_magazines': 'Книжки та журнали',
    'common.partners_products.large_format_products': 'Широкоформатна продукція',
    'common.partners_products.fabric_printing': 'Друк на тканині',
    'common.partners_products.silkscreen': 'Шовкографія',
    'common.partners_products.other': 'Інше',
    'common.partners_modules.bitrix': 'Бітрікс24',
    'common.partners_modules.roll_print': 'Рулонний друк',
    'common.partners_modules.personal_server': 'Налаштування особистого серверу',
    'common.partners_modules.alternative_design': 'Альтернативні стилі для сайту',
    'common.partners_modules.personal_site': 'Персональний сайт-магазин',
    'common.partners_modules.large_format_print': 'Широкоформатний друк',
    'common.partners_modules.offset_print': 'Офсетний друк (розробка плануєтсья)',
    'common.partners_modules.list_labels': 'Наліпки в аркушах',
    'common.partners_modules.prepress_auto': 'Афтоматизація препресу',
    'common.partners_modules.contactors': 'Робота з підрядниками',
    'common.partners_modules.stock': 'Склад',
    'common.partners_modules.team_management': 'Керування командою',

    // WT-1008:
    'pages.item_key': 'Сторінка',
    'pages.item_new': 'Нова сторінка',

    // WT-987:
    'menu.partnerPages': 'Сторінки druk.ua',
    'form.pl_urlpage': 'Посилання на сторінку (pl)',
    'partnerPages.index_title': 'Сторінки druk.ua',
    'partnerPages.modal_create_title': 'Створити сторінку для druk.ua',
    'partnerPages.edit_title': 'Сторінка',
    'partnerPages.edit.main_section_title': 'Основна інформація',
    'partnerPages.edit_warning': 'Сторінка не була сконфігурована розробником!',
    'partnerPages.edit_prompt':
        'Усі поля введення цього блоку підтримують як звичайний текст, так і html-теги з інлайновими стилями, наприклад <span style="color: #bdbdbd;">Lorem Ipsum</span>',
    'promo.section': 'Головний екран',
    'section.content': 'Наповнення',
    'section.block_content': 'Наповнення блоку',
    'form.img': 'Зображення',
    'form.main_title': 'Заголовок',
    'form.subtitle': 'Підзаголовок',
    'form.button': 'Текст кнопки',
    'form.menu_option': 'Пункт меню',
    'form.block_img': 'Зображення блоку',
    'form.block_title': 'Заголовок блок',
    'form.block_text': 'Текст блоку',
    'partnerPages.lead_title': 'Лідер проєкту',
    'form.left_text': 'Ліва колонка',
    'form.right_text': 'Права колонка',
    'form.card_title': 'Заголовок картки',
    'form.card_text': 'Текст картки',
    'partner.contact_title': "Форма зворотного зв'язку",
    'form.field_title': 'Заголовок поля',
    'marketplace.about.page_title': 'Про компанію',
    'form.text': 'Текст',
    'form.partner_button': 'Текст партнерської кнопки',
    'form.redirect_button': 'Текст кнопки редіректу',
    'form.menu_title': 'Заголовок списку',
    'marketplace.druk.footer_title_alt': 'Стати партнером DRUK',
    'marketplace.partners_form': 'Партнерська форма',
    'form.form_title': 'Заголовок блоку',
    'form.field_title_ty': 'Заголовок поля "Типографія"',
    'form.field_title_name': 'Заголовок поля "Ваше ім\'я"',
    'form.field_title_phone': 'Заголовок поля "Телефон"',
    'form.field_title_mail': 'Заголовок поля "Email"',
    'form.field_title_city': 'Заголовок поля "Місто"',
    'form.field_title_site': 'Заголовок поля "Сайт типографії"',
    'form.field_title_code': 'Заголовок поля "Код ЄДРПОУ"',
    'form.field_title_empl': 'Заголовок поля "Кількість працівників"',
    'form.checkbox_g_title': 'Заголовок групи чекбоксів',
    'form.field_title_src': 'Заголовок поля "Джерело"',
    'form.field_title_cmt': 'Заголовок поля "Коментар"',
    'form.body': 'Тіло форми',
    'form.prompt': 'Підказка',
    'form.check_label': 'Заголовок посилання',
    'form.check_link': 'Текст посилання',
    'partnerPages.edit.seo_settings': 'Налаштування SEO',
    'form.field_title_name_alt': 'Заголовок поля "Ім\'я"',
    'form.field_title_msg': 'Заголовок поля "Текст повідовлення"',
    'partner_pages.is_restored': 'Було відновлено раніше видалену сторінку',
    'partnerPages.loading': 'Триває обробка сторінки',
    'partnerPages.disabled_prompt': '*На сайті підключена форма з Hubspot, редагування полів не вплине на сторінку сайту',

    // WT-990:
    'tasks.modal_product_tag.title': 'Попередній перегляд бірки',
    'tasks.modal_product_tag.order_id': 'Замовлення №',
    'tasks.modal_product_tag.form_client': 'Замовник',
    'common.form_manager': 'Менеджер',
    'tasks.modal_product_tag.form_date': 'Дата пакування',
    'tasks.modal_product_tag.form_places': 'Місць',
    'common.on_print': 'Друкувати',

    // WT-1064:
    'order.user_updated': 'Персональні дані оновлено',

    // WT-804:
    'custom_sorting.prompt': "Сортування зроблено за необов'язковим параметром лише для рядків, де він вказаний.",
    'custom_sorting.link': 'Повернутись до повного списку',

    // WT-871:
    'order_creation.placing.product_item.comment_edit': 'Змінити коментар для тех.карти',
    'order_creation.placing.product_item.comment_add': 'Додати коментар для тех.карти',
    'order_creation.placing.product_item.modal_title': 'Примітки до товару',
    'operations.commets_are_absent': 'До цього товару/задачі коментарі відсутні...',
    'copy_order.copying_order_messages_prompt': 'У замовленні є коментарі. Копіювати  разом з ними?',
    'orders.items.all_comments': 'Переглянути всі коментарі',
    'messages.is_tech_map_comment': '*Цей коментар транслюється у технічну карту!',

    // WT-903:
    'order_item.card.delivery_title': 'Доставка',
    'delivery_edit.modal_title': 'Редагувати адресу доставки',
    'delivery_edit.edit_tab': 'Вибір адреси',
    'delivery_edit.create_tab': 'Створення адреси',
    'delivery_edit.modal.edit_form_title': 'Параметри адреси',
    'delivery_edit.modal.from_item.picked_address': 'Обрана адреса',
    'delivery_edit.modal.from_prompt': '*Ви не можете видалити останню адресу',
    'delivery_edit.modal.from_manual_prompt': '*Ви не можете видалити адресу самовивозу',
    'delivery_edit.modal.deleting_prompt': 'Адресу видалено. До замовлення додано останню адресу',
    'contact_item.card.delivery_title': 'Адреси доставки',
    'contact_item.card.last_address': 'Остання адреса',

    // WT-1050:
    'tasks.logistic.tag_print': 'Друкувати бірку',

    // WT-983:
    'metrics.units': 'од',

    // WT-1098:
    'orders.index.date': 'Дата створення',
    'orders.index.ready_on': 'Готовність на',
    'orders.index.ready_for': 'Попередньо на',
    'orders.index.products': 'Товарів',
    'orders.item.address_edit': 'Редагувати адресу',
    'orders.item.order_history': 'Історія замовлення',
    'orders.item.product_mockups_empty': 'Макети відсутні!',
    'orders.item.product_mockups_success': 'З макетами все впорядку!',
    'orders.item.product_mockups_error': 'З макетами виникли проблеми!',
    'common.order_product.is_paid': 'Оплачений',
    'common.order_product.is_not_paid': 'Неоплачений',

    // WT-1122:
    'common.module_loading': 'Триває обробка модулю...',

    // WT-1041:
    'modules.alternative_design.views_option': 'Прибрати слайдер з вибором виду товару',
    'modules.alternative_design.views_prompt':
        '*Активне положення перемикача прибирає відображення слайдеру з видами на сторінці товару',
    'modules.alternative_design.price_option': 'Встановити головною ціну за тираж',
    'modules.alternative_design.price_prompt': '*Активне положення перемикача встановлює ціну за тираж головною на сторінці товару',
    'modules.alternative_design.preview_modal.title': 'Попередній перегляд налаштування дизайну',

    // WT-1129:
    'transactions.index.auto_generated': 'Automation for',

    // WT-1079:
    'mockup.card_file.link_prompt': 'Посилання скопійовано!',
    'mockup.file_card.needed_mockup': 'Потрібен макет',
    'mockup.file_card.mockup_not_req': 'Порожня сторінка',
    'mockup.modal_link.title': 'Посилання на макет',

    // WT-866:
    'marketplace.druk.reasons_title': 'Модуль вам потрібен, якщо',
    'marketplace.druk.description_title': 'Детальний опис функціоналу',
    'marketplace.druk.benefits_title': 'Що ви отримаєте після підключення модуля "Таск менеджер"',
    'marketplace.druk.recommendations_title': 'З модулем "Таск менеджер" рекомендуємо підключити',
    'marketplace.druk.demo_title': 'Замовляйте безкоштовну демонстрацію',

    // WT-1158:
    'common.table.loading': 'Завантаження записів...',

    // WT-1146:
    'common.author': 'Автор',

    // WT-802:
    'create.finish.manager.change': 'Ви можете обрати іншого менеджера для поточного замовлення',
    'create.finish.manager.order': 'Менеджер замовлення',
    'order_item.change_manager': 'Перепризначити менеджера',

    // WT-1153:
    'orders_item.on_cancel_order': 'Скасувати замовлення',
    'orders_item.want_to_cancel_prompt': 'Ви дійсно хочете скасувати замовлення?',
    'orders_item.product_item.want_to_cancel_prompt': 'Ви дійсно хочете скасувати виготовлення товару?',

    // WT-999:
    'common.placeholder.start_typing': 'Почніть вводити для пошуку...',
    'common.multiple_async.no_result': 'Записів не знайдено',
    'common.multiple_async.empty_list': 'Список порожній. Введіть принаймні 3 символи для пошуку',
    'common.order_created_at': 'Дата замовлення',

    // WT-1190:
    'btn.agree': 'Зрозуміло',
    'login.locked_modal.title': 'Стривайте',
    'login.locked_modal.message': 'Please wait, creating printhouse account in progress. This may take some time',
    'typographies.locked_prompt': 'Копіюється...',

    // WT-1079:
    'mockup.card_file.general_link_button': 'Посилання на макети',

    // WT-1117:
    'contacts.creation_prompt': '*Після створення акаунту ми автоматично відправимо лист з обліковими данними Вашому клієнту',

    // WT-1227:
    'partnerPages.item.button.add_menu_option': 'Додати пункт меню',
    'partnerPages.item.button.add_list_option': 'Додати пункт списку',
    'partnerPages.item.button.add_section': 'Додати блок',
    'partnerPages.item.button.remove_section': 'Видалити блок',
    'partnerPages.item.link_prompt': 'Впишіть URL-адресу сторінки без домену, наприклад - /modules/my-module',
    'common.symbols': 'Символів',
    'common.has_visible': 'Видимий',

    // HotFix 30_5_23 (Bezier):
    'equep.curve.prompt_has_not_tables': 'Діапазони не задано',

    // HotFix 31_5_23 (Roles):
    'roles.index.has_no_role_translation': 'Відсутній переклад	для обраної мови',

    // WT-1053:
    'employees.item.is_auto_assigned': 'Призначати клієнтів з сайту',
    'employees.index.is_auto_assigned': 'Призначаютсья клієнти з сайту',

    // WT-1159:
    'products_import.index.title': 'Імпорт товарів з бібліотеки',
    'products_import.index.backward': 'Повернутись до свого каталогу',

    // WT-1178:
    'subcatalogs.index.title': 'Підкатегорії',
    'subcatalogs.index.create': 'Створити підкатегорію',
    'subcatalogs.index.create_product': 'Створити товар',

    // WT-467:
    'common.material_remains.ends': 'Закінчується',
    'common.material_remains.finished': 'Закінчився',
    'common.material_remains.sufficiently': 'В наявності',
    'common.material_remains': 'Наявність на складі',

    // WT-1102:
    'create_order.product.store_material_count': 'Наявність на складі',
    'create_order.product.remains_material_count': 'Залишки після виготовлення',
    'librery.algorithms.components.workItem.resetParams': 'Скинути параметри',
    'modal_custom.has_materials_errors': 'Одного з матеріалів може бути недостатньо для замовлення',
    'modal_custom.has_material_errors': 'Матеріал закінчується',

    // WT-1155:
    'permissions.front_ordermockup.title': 'Макети замовлень',

    // WT-1126:
    'marketplace.policy.page_title': 'Політика конфіденційності',
    'marketplace.end_user_license.page_title': 'End-User-License',
    'form.content': 'Контент',

    // WT-1272:
    'orders.search_roar': 'Пошук проведений за назвами, номерами та коментарями до замовлень і товарів',

    // WT-1100:
    'equipments.delete.prompt_equipment_used_in_works': 'Неможливо видалити обладнання, оскільки воно використовується у роботах',
    'equipments.delete.prompt_equipment_used_in_algs': 'Неможливо видалити обладнання, оскільки воно використовується у алгоритмах',
    'equipments.delete.modal_title': 'Використання',
    'equipments.delete.modal_works': 'Роботи',
    'equipments.delete.modal_algs': 'Алгоритми',
    'materials.delete.prompt_material_used_in_works': 'Неможливо видалити матеріал, оскільки він використовується у роботах',
    'materials.delete.prompt_material_used_in_algs': 'Неможливо видалити матеріал, оскільки він використовується у алгоритмах',
    'materials.delete.modal_title': 'Використання',
    'materials.delete.modal_works': 'Роботи',
    'materials.delete.modal_algs': 'Алгоритми',
    'works.delete.prompt_work_used_in_algs': 'Неможливо видалити роботу, оскільки вона використовується у алгоритмах',
    'works.delete.modal_title': 'Використання',
    'works.delete.modal_algs': 'Алгоритми',
    'algorithms.delete.modal_title': 'Використання',
    'algorithms.delete.modal_products': 'Товари',
    'algorithms.delete.modal_views': 'Види',
    'algorithms.delete.prompt_algorithm_used_in_products':
        'Неможливо видалити алгоритм, оскільки він бере участь у виготовленні товарів',

    // WT-1159:
    'products_import.item.title': 'Попередній перегляд товару',
    'products_import.item.views_title': 'Доступні види товару',
    'products_import.view.title': 'Попередній перегляд виду',

    // WT-1176:
    'tasks.table.completed_at': 'Дата завершення',
    'tasks.modal_export.title': 'Створити звіт',
    'tasks.modal_export.period': 'Період',
    'tasks.modal_export.created_at': 'Дата створення завдання',
    'tasks.modal_export.completed_at': 'Дата завершення завдання',
    'btn.download': 'Завантажити',

    // WT-861:
    'widgets.stripe.public_key': 'Публічний ключ',
    'widgets.stripe.secret_key': 'Секретний ключ',
    'widgets.stripe.webhook_key': 'Ключ для роботи з callback методом',

    // WT-1032:
    'stock.internal_cut.title': 'Порізка матеріалу',
    'stock.internal_cut.material': 'Матеріал',
    'stock.internal_cut.kind': 'Вид',
    'stock.internal_cut.cut_format': 'Формат для порізки',
    'stock.internal_cut.new_format': 'Отримуваний формат',
    'stock.internal_cut.cut_amount': 'Кількість (арк)',
    'stock.internal_cut.new_amount': 'Результат порізки',
    'stock.internal_cut.stock_amount': 'Кількість матеріалу на складі',
    'stock.internal_cut.stock_price': 'Загальна вартість',
    'stock.internal_cut.stock_unit_price': 'Собівартість одиниці матеріалу',
    'stock.internal_cut.cut_is_unavailable': 'Неможливо здійснити порізку для обраного формату',
    'stock.internal_cut.loading': 'Розрахунок порізки',
    'stock.internal_cut.amount_error': 'На складі відсутня достатня кількість матеріалу',
    'stock.opeartion_type.receipt_item': 'Закупівля',
    'stock.opeartion_type.write_off': 'Списання',
    'stock.opeartion_type.consumption': 'Витрати',
    'stock.opeartion_type.internal': 'Внутрішня операція',

    // WT-1199:
    'material.view_save.actualize_modal.title': 'Зміна кількості матеріалу',
    'material.view_save.actualize_modal.prompt':
        'Будь ласка, опишіть причину зміну фактичної кількості матеріалу на складі. У розділі "Рух матеріалу" створиться відповідна внутрішня операція',
    'material.view_save.actualize_modal.count_warning': 'Кількість матеріалу не була змінена',

    // WT-1249:
    'tasks.filters.assignee_without': 'Не призначено',

    // WT-1052:
    'contractors.import.file_example': 'Завантажити приклад файлу',

    // WT-1283:
    'sort.by_default': 'Основне',
    'common.nested': 'Вкладеностей',

    // WT-1324:
    'typography.save.default_currency': 'Валюта типографії',
    'typography.save.rate_to_the_euro': 'Курс до євро',

    // WT-1356:
    'common.website_languages': 'Мови веб-сайту',

    // WT-1307:
    'typographies.tab_delivery': 'Доставка',
    'typographies.delivery.nova_poshta': 'Нова Пошта',
    'typographies.delivery.dpd_baltic': 'DPD Baltic',
    'delivery.api_key': 'API-ключ',
    'delivery.city': 'Місто відправки',
    'delivery.warehouse': 'Відділення відправки',
    'delivery.api_key_prompt': 'Для налаштування модулю вам потрібно зберегти API-ключ',
    'delivery.api_key_error': 'Відсутні налаштування модулю',
    'common.async_prompt': 'Введіть принаймні 3 символи для пошуку',
    'common.data_loading': 'Завантаження даних',
    'create.delivery.form.street_id': 'Вулиця',
    'create.delivery.form.street_no': 'Будинок',
    'create.delivery.form.apartment': 'Квартира',
    'create.delivery.form.postal_code': 'Поштовий індекс',
    'address.apartment.short': 'APT',
    'delivery.postal_code.prompt': 'Поштовий індекс не повинен містити код країни',
    'order.express_invoice_generation': 'Зненерувати ТТН',

    // WT-1373:
    'orders.generate_invoice_modal.invoice_lang': 'Мова розрахункового рахунку',

    // WT-760:
    'orders_item.want_to_cancel_balance': 'Кошти будуть повернуті на баланс клієнта',
    'common.transaction_types.refund': 'Повернення коштів на баланс клієнта',

    // WT-1366:
    'product.count_step': 'Доступний крок тиражу',
    'product.count_step_prompt': 'Впливає на доступний тираж для замовлення на сайті',

    // WT-1092:
    'orders.dashboard.orders_total_count': 'Загальна кількість замовлень',
    'orders.dashboard.orders_total_price': 'Загальна сума',
    'orders.dashboard.orders_average_price': 'Середній чек',

    // WT-567:
    'bezier.ranges_prompt':
        'Побудована крива базується на даних, що вказані в діапазонах. Власні дані кривої для поточного виду устаткування',

    // Partners:
    'marketplace.terms.page_title': 'Умови користування',

    // WT-1228:
    'categories.create': 'Створити категорію',
    'categories.prompt':
        'Зверніть увагу, що каталог, який містить власні товари, не може містити вкладені категорії! \nЯкщо ви створюєте вкладений каталог, товари можуть бути імпортовані у нього (чи в окремий підкаталог) або будуть видалені! \nЦе правило працює ідентинтично в інший бік. Система буде попереджати Вас про це',
    'categories.subcatalogs_does_not_exist': 'Підкатегорії відсутні...',
    'common.published_feminine': 'Опублікована',
    'common.not_published_feminine': 'Неопублікована',
    'categories.warning.catalogs_sorting': 'Сортувати можна тільки опубліковані категорії',
    'categories.products_does_not_exist': 'Товари відсутні...',
    'products.import_list': 'Імпортувати товари',
    'products.import_map': 'Ви обрали такі товари для імпорту',
    'categories.delete': 'Видалити каталог',
    'categories.delete.has_children': 'Ви видаляєте категорію "{{catalog}}", яка містить у собі вкладені',
    'categories.delete_prompt':
        'Оберіть батьківський каталог, у який ви бажаєте їх імпортувати. Залиште поле пустим, якщо бажаєте видалити ці',
    'categories.new_parent': 'Новий батьківський каталог',
    'categories.create.has_products': 'Ви створюєте вкладений каталог для категорії "{{catalog}}", яка містить у собі товари',
    'categories.destination.import_to_created': 'Імпортувати до створюваної категорії',
    'categories.destination.import_to_new': 'Імпортувати в нову категорію',
    'categories.products_destination': 'Призначення товарів',
    'categories.create_prompt':
        'Ви можете імпортувати товари у створюваний каталог, імпортувати їх до нового каталогу (який створиться автоматично), або видалити. Оберіть відповідний варіант',
    'catalog.create.new_title': 'Назва нового каталогу',
    'categories.edit': 'Редагування категорії',
    'categories.edit.has_children':
        'Ви перетворюєте категорію "{{catalog}}" у підлеглий каталог для категорії "{{parent}}". Категорія "{{catalog}}" містить у собі вкладені категорії',
    'categories.destination.import_to_same': 'Імпортувати до каталогу "{{catalog}}"',
    'categories.destination.import_to_another': 'Імпортувати в інший каталог',
    'categories.destination.save_separate': 'Зберегти окремо',
    'categories.categories_destination': 'Призначення каталогів',
    'categories.edit_prompt':
        'Ви можете імпортувати ці каталоги у каталог "{{parent}}" окремо від категорії "{{catalog}}", імпортувати їх в інших каталог, перетворити їх на окремі батьківські каталоги, або видалити. Оберіть відповідний варіант',
    'catalog.is_empty': 'Каталог порожній',
    'catalog.go_to': 'Перейти до каталогу',
    'products.mass_deletion': 'Увага! Ви збираєтеся видалити наступні товари',
    'products_import.category_has_nested':
        'Ви збираєтеся імпортувати товар(-и) <b>"{{products}}"</b> до категорії "{{catalog}}", яка містить у собі вкладені каталоги',
    'products_import.category_prompt':
        'Ви можете імпортувати ці каталоги в інших каталог, перетворити їх на окремі батьківські каталоги, або видалити. Оберіть відповідний варіант',
    'categories.mass_deletion':
        'Увага! Ви збираєтеся видалити всі вкладені категорії, які містять товари: <b>{{products}}</b>, що видаляться разом з ними',
    'products_create.category_has_nested':
        'Ви збираєтеся створити товар у категорії "{{catalog}}", яка містить у собі вкладені каталоги',
    'products.create': 'Створити товар',

    // WT-1025
    'products.creating': 'Новий товар',
    'product.type': 'Тип товару',
    'product.types.classic': 'Класичний',
    'product.types.one_kind': 'Один вид',
    'common.form_error': 'Форма має порожні або невалідні значення',
    'common.management': 'Менеджмент',
    'products.recs_list': 'Рекомендовані товари',
    'common.list_is_empty': 'Список порожній...',
    'common.circle': 'Коло',
    'common.rectangle': 'Прямокутник',
    'common.rectangle_rounded': 'Прямокутник із заокругленими кутами',
    'common.free_shape': 'Вільна форма',
    'products.edit': 'Редагування товару',
    'products.type_changing.prompt':
        '<b>Увага!</b> Ви збираєтеся перетворити класичний товар на товар з одним видом. Будь ласка, виберіть вид товару, який стане основою вашого товару. <b>Всі інші види будуть видалені</b>',
    'product.one_kind_prompt': 'Товар з одним видом не передбачає створення окремих видів товару',
    'product.classic_prompt': '"Класичний" товар передбачає створення окремих видів товару',
    'products.save_wizard_stages.main': 'Опис товару',
    'products.save_wizard_stages.seo': 'SEO-дані',
    'products.save_wizard_stages.tech': 'Технічні характеристики',
    'products.save_wizard_stages.preview': 'Попередній перегляд',
    'product.seo_prompt': 'Загальні SEO-дані сторінки',
    'og_image.prompt': 'Рекомендований розмір: 1200*630px',
    'common.image_is_missing': 'Зображення відсутнє',
    'common.is_missing': 'відсутній',

    'product.count': 'Початковий тираж',
    'common.seo.data': 'SEO-дані',
    'product.og.title': 'OG-розмітка',
    'product.og.info': 'Використовується для прев’ю вашої сторінки у соц.мережах',
    'product.form.meta.title': 'SEO-заголовок',
    'product.form.meta.description': 'SEO-опис',
    'product.og.form.title': 'OG-заголовок',
    'product.og.form.description': 'OG-заголовок',

    // WT-283:
    'librery.works.components.formSave.work_type_prompt': 'Оберіть тип роботи, для генерації задач в "Таск-менеджері"',

    // WT-732:
    'equipments.work_mode_properties.prompt':
        'Характеристики - це текстові дані, що описують те, як можна використовувати обладнання. Наприклад, довжина матеріалу, який можна використовувати на обладнанні',
    'equipments.work_mode_params.prompt': 'Параметри - це числові дані, які допоможуть під час розрахунку використання обладнання',
    'materials.work_mode_properties.prompt':
        'Характеристики - це текстові дані, що описують різновиди матеріалу, потрібні для того, аби обрати конкретний матеріал для виготовлення. Наприклад, колір матеріалу.',
    'materials.work_mode_params.prompt':
        'Параметри - це числові дані обраного типу матеріалу, які допоможуть під час розрахунку вартості, ваги, тиражу з використання матеріалу',

    // WT-1328:
    'common.tech_map': 'Технічна карта',
    'common.mockup_checking': 'Перевірка макету',
    'common.mockup_line_generation': 'Генерація спуску смуг',
    'products.prepress_card_prompt': 'Задача автоматично згенерується у модуль "Таск Менеджер"',
    'tech_map_editor.title': 'Подання',
    'tech_map_editor.mode_shortly': 'Стисле',
    'tech_map_editor.mode_expanded': 'Розгорнуте',
    'tech_map_editor.mode_own': 'Власне',
    'tech_map_editor.new_detail': 'Нова деталь',
    'common.product_assembly': 'Збірка товару',

    'equep.view.title': 'Режими роботи',
    'librery.equipments.EquipmentsViewSave.title': 'режим роботи',
    'equep.view.add': 'Додати режим роботи',
    'common.prepress': 'Додрукарська обробка',

    // WT-1385:
    'kinds.save_wizard_stages.main': 'Опису виду',
    'kinds.creating': 'Створення виду',
    'form.default': 'Стандартний',

    // WT-1401:
    'common.not_done': 'Незавершений',
    'products.save_wizard_stages.seo_not_done': 'Стане доступним після завершення етапу "SEO-дані"',
    'products.save_wizard_stages.tech_not_done': 'Стане доступним після завершення етапу "Технічні характеристики"',
    'products.save_wizard_stages.tech_steps.general': 'Загальна інформація',
    'products.save_wizard_stages.tech_steps.detail': 'Деталь',
    'products.save_wizard_stages.tech_steps.add_detail': 'Додати готову деталь',
    'products.save_wizard_stages.tech_steps.create_detail': 'Створити нову деталь',
    'products.save_wizard_stages.tech_steps.algorithm': 'Алгоритм',
    'products.save_wizard_stages.tech_steps.add_alg': 'Додати готовий алгоритм',
    'products.save_wizard_stages.tech_steps.create_alg': 'Створити новий алгоритм',
    'products.save_wizard_stages.tech_steps.details_is_missing': 'Для початку роботи з алгоритмами оберіть деталь',
    'wizard.step_unavailable': 'Етап недоступний',
    'product.calculation_type': 'Тип розрахунку',
    'products.calculation_types.full': 'Повний розрахунок вартості',
    'products.calculation_types.partial': 'Частковий розрахунок вартості',
    full_calculation_prompt:
        '"Повний розрахуное вартості" передбачає налаштування технічної карти для товару, на основні якої визначатиметься ціна за тираж та одиницю',
    partial_calculation_prompt:
        '"Частковий розрахунок вартості" передбачає встановлення жорстких параметрів для товару, на основні яких визначатиметься ціна за тираж та одиницю',
    'wizard.details_prompt': "Для відобрження прев'ю технічної карти додайте принаймні одну деталь",
    'common.algorithm_sm': 'алгоритм',
    'works.new_item': 'Нова робота',
    'algorithm.steps': 'Послідовність операцій',
    'common.data_processing': 'Обробка даних...',
    'common.equipment_sm': 'обладнання',
    'common.material_sm': 'матеріал',
    'common.characteristics_sm': 'характеристики',
    'common.characteristic_sm_genitive': 'характеристику',
    'common.characteristic_sm': 'характеристика',
    'common.params_sm': 'параметри',
    'common.param_sm_ablative': 'параметру',
    'common.param_sm': 'параметр',
    'common.new_neuter': 'Нове',
    'common.value_sm': 'значення',
    'common.material_kind_sm': 'вид матеріалу',
    'common.equipment_mode_sm': 'режим роботи',
    'equipment.mode_save.params_prompt': 'Режим роботи не може бути створений без характеристики',
    'products.cancel_creation': 'Скасування створення товару',
    'products.cancel_creation.prompt':
        "Стривайте! Під час створення товару ви створили нові сутності у системі та/або побудували зв'язки між ними.",
    'common.material_kinds': 'Види матеріалів',
    'products.cancel_creation.delete_prompt': 'Бажаєте видалити їх, чи зберегти?',
    'product.go_back': 'Повернутися до товару',
    'common.calculate': 'Обчислити',

    // WT-1218
    'metarials.kind_save.params_prompt': 'Вид матеріалу не може бути створений без характеристики',

    // WT-1408:
    'root.prompt': 'Увага! Ви працюєте у режимі супер-користувача!',

    // WT-1454:
    'common.language': 'Мова',
    'common.without_translation': 'Translation does not exist',

    // WT-1455:
    'order_status.waiting': 'Узятий до виробництва',

    // WT-1340:
    'partnerPages.pricing.server_title': 'Персональна серверна версія',
    'partnerPages.pricing.questions_title': 'Найчастіші запитання',
    'partnerPages.pricing.integration_title': 'Інтеграція',
    'partnerPages.item.button.add_card': 'Додати картку',
    'partnerPages.item.button.remove_card': 'Видалити картку',
    'form.list_option': 'Пункт списку',
    'pricing.plan_alias': 'Ключ плану',
    'pricing.plan_title': 'Назва плану',
    'pricing.plan_month_price': 'Місячна вартість',
    'pricing.plan_year_price': 'Річна вартість',
    'partnerPages.pricing.plans': 'Плани підписки',
    'partnerPages.pricing.plans_benefits': 'Переваги плану підписки',
    'partnerPages.item.button.add_plan': 'Додати план підписки',
    'partnerPages.item.button.remove_plan': 'Видалити план підписки',
    'common.unavailable_f': 'Доступна',
    'common.available_f': 'Недоступна',
    'common.always_show': 'Відображати завжди',
    'common.always_show.mobile_prompt': 'Цей елемент відображатиметься завжди під час мобільної адаптації',
    'partnerPages.item.button.add_benefit': 'Додати перевагу плану підписки',
    'common.benefit': 'Перевага',
    'partnerPages.pricing.plans_table': 'Таблиця планів',
    'form.picker': 'Перемикач',

    // HotFix:
    'typog.design.invoice.logo.info': 'Рекомендовані розміри логотипу: Висота — від 90px до 180px; Ширина — від 90px до 180px;',

    // WT-1473:
    'common.delete_modal_title': 'Використання',
    'formats.delete_prompt.materials': 'Неможливо видалити формат, оскільки він використовується у матеріалах',
    'formats.delete_prompt.products': 'Неможливо видалити формат, оскільки він використовується у товарах',
    'formats.delete_prompt.productsKinds': 'Неможливо видалити формат, оскільки він бере участь у виготовленні видів товарів',

    // WT-1472:
    'common.and': 'та',
    'details.delete_prompt.products': 'Неможливо видалити деталь, оскільки вона бере участь у виготовленні товарів',

    // WT-301:
    'products.calculation_types.warehouse': 'Товар зі складу',
    warehouse_calculation_prompt:
        '"Товар зі складу" передбачає встановлення ціни за товар та його кількості на складі. У подальшому ви зможете керувати постачаннями такого товару у модулі "Склад"',
    'products.calculation_varities.fixed': 'Фіксована вартість',
    'products.calculation_varities.range': 'Діапазон цін',
    'products.calculation_varities.curve': 'Крива Безьє',
    'product.calc_variety': 'Вид розрахунку',
    'kinds.cancel_creation': 'Скасування створення виду',
    'kind.go_back': 'Повернутися до виду',

    // WT-417:
    'menu.ready_products': 'Готові товари',
    'ready_products.title': 'Наявність готових товарів',
    'sort.by_price': 'За ціною',
    'ready_products.receipt': 'Надходження товару',
    'common.product_kind': 'Вид товару',
    'ready_products.price_for_product': 'Сума за постачання товару',
    'ready_products.movement_title': 'Рух товару',
    'ready_products.booking_title': 'Бронювання готових товарів',
    'stock.opeartion_type.receipt': 'Надходження на склад',
    'ready_product.check_products_booking': 'Переглянути бронювання товарів',
    'common.substructing': 'Різниця',

    // WT-1457:
    'works.material.has_params_without_options':
        'Неможливо створити роботу з вказаною конфігурацією матеріалу, оскільки він не містить деяких характеристик та/або параметрів',
    'works.equipment.has_params_without_options':
        'Неможливо створити роботу з вказаною конфігурацією обладнання, оскільки воно не містить деяких характеристик та/або параметрів',
    'common.work_loading': 'Триває завантаження роботи',

    // WT-1515:
    'kind.range.title': 'Ціна виду товару за діапазоном кліків',
    'product.range.title': 'Ціна товару за діапазоном кліків',

    // WT-1516:
    'common.locked_values': 'Заблоковані значення',
    'ranges.prompt':
        'За допомогою лівої кнопки миші ви можете перетягувати значення діапазонів. За допомогою правої - відкрити контекстне меню для тонкого налаштування',

    // WT-1517
    'equep.curve.clicks_sm': 'кліків',
    'equep.curve_title': 'Ціна операції за Кривою Безьє',
    'kind.curve_title': 'Ціна одиниці виду товару за Кривою Безьє',
    'product.curve_title': 'Ціна одиниці товару за Кривою Безьє',
    'equipments.on_set_ranges': 'Встановити діапазони',
    'equep.curve.set': 'Введіть параметри кривої',

    // WT-1519:
    'common.algorithm_sm_genitive': 'алгоритму',
    'wizard.operations_prompt': "Для відображення прев'ю додайте принаймні одну роботу",
    'common.operation_sm_genitive': 'роботи',

    // WT-1513:
    'product.curve.for.click': 'Тираж',
    'kind.curve.for.click': 'Тираж',
    'product.curve.price.count': 'Граничний тираж',
    'kind.curve.price.count': 'Граничний тираж',
    'product.curve.clicks_sm': 'товар(-ів)',
    'kind.curve.clicks_sm': 'товар(-ів)',

    // WT-1571:
    'common.meta': 'Мета-дані',
    'common.has_no_data': 'Параметри відсутні',

    // WT-1468:
    'products.formats_availability_prompt':
        'Налаштування форматів стане доступним після створення/додавання деталей до технологічної картки товару чи його виду',
    'products.formats_is_empty_prompt':
        'Увага! Види товару <b>{{kinds}}</b> містять деталі <b>{{details}}</b>, для яких встановлений параметр "Використовувати формат товару". Додайте до товару формати для коректного розрахунку ціни',
    'products.has_kinds_with_static_formats_prompt':
        'Для даного товару доступні сталі формати: <b>{{formats}}</b>, встановлені у деталях його видів',
    'kinds.details.only_static_formats_available':
        'Для видів товару <b>{{product}}</b> доступний тільки вибір сталих форматів. Вже наявні формати в інших видах: <b>{{formats}}</b>',
    'kinds.details.only_product_formats_available': 'Для видів товару <b>{{product}}</b> недоступний вибір сталих форматів',
    'products.one_kind.formats_is_empty_prompt':
        'Увага! Товар містить  деталі <b>{{details}}</b>, для яких встановлений параметр "Використовувати формат товару". Додайте до товару формати для коректного розрахунку ціни',
    'products.one_kind.static_formats_prompt':
        'Для даного товару доступні сталі формати: <b>{{formats}}</b>, встановлені у його деталях',
    'products.details.only_static_formats_available':
        'Для товару <b>{{product}}</b> доступний тільки вибір сталих форматів. Вже наявні формати: <b>{{formats}}</b>',
    'products.details.only_product_formats_available': 'Для товару <b>{{product}}</b> недоступний вибір сталих форматів',

    // WT-1540:
    'products.publish_popular_prompt': 'Увага! Статус "Популярний" недоступний для неопублікованих товарів',

    // WT-1529:
    'categories.publish_popular_prompt': 'Увага! Статус "Популярний" недоступний для неопублікованих категорій',

    // WT-1534:
    'warehouse.limited_product_prompt':
        'Увага! Даний товар є в наявності на складі у кількості <b>{{maxCount}}</b> одиниць. Ви не можете створити замовлення з більшою кількістю',
    'warehouse.product_is_not_available': 'Даного товару немає в наявності на складі',
    'warehouse.booked_item.product_stock_remains': 'Залишок товару на складі',
    'warehouse.booked_item.limited_product_prompt':
        'Увага! Тираж для даної позиції не може перевищувати <b>{{maxCount}}</b>. Ви можете встановити менший тираж для неї, або для інших ідентичних товарів у замовленні',
    'warehouse.booked_item.product_is_not_enough': 'Даного товару недостатньо для такого замовлення',
    'warehouse.booked_item.order_is_no_possible': 'Увага! Замовлення містить такий самий товар з невіришеною проблемою з тиражем',
    'kinds.is_preorder_available': 'Можна замовляти наперед',
    'products.one_kind.change_to_warehouse_calc_type':
        'Ви змінюєте тип розранку для товару на "Товар зі складу", який раніше вже був представлений на складі у кількості <b>{{count}}</b> одиниць. Ви бажаєте анулювати цю кількість?',
    'products.classic.change_to_warehouse_calc_type':
        'Ви змінюєте тип розранку для товару на "Товар зі складу", який раніше вже був представлений на складі. Знайдені записи про його види:<br>{{kinds}}<br>Ви бажаєте анулювати їхню кількість?',
    'common.no_save': 'Ні, зберегти',
    'stock.opeartion_type.cancellation': 'Ануляція',
    'products.stock_movement': 'Рух товару на складі',
    'products.stock_kinds_remains': 'Наявність видів товару на складі',
    'products.save_wizard_stages.not_done': 'Стане доступним після завершення створення',
    'orders_item.no_product_in_stock': 'Увага! Товару немає на складі',
    'orders_item.product_is_not_enough_in_stock': 'Увага! На складі не вистачає <b>{{count}}</b> одиниць товару для даного замовлення',

    // WT-775:
    'common.settings_standard': 'Загальні',
    'common.settings_detail': 'Деталь',
    'details.use_typography_settings': 'Застосувати стандартні налаштування',
    'detail.min_width': 'Мінімальна ширина (мм)',
    'detail.min_height': 'Мінімальна висота (мм)',
    'detail.min_angle_corner_radius': 'Мін. радіус заокруглення кута (°)',
    'detail.min_bleeds': 'Мін. відстань до краю стрічки (мм)',
    'detail.min_padding': 'Мін. відстань між етикетками (мм)',
    'detail.max_angle_corner_radius': 'Макс. радіус заокруглення кута (°)',
    'detail.default_angle_corner_radius': 'Станд. радіус заокруглення кута (°)',
    'calculation.payload.width_is_too_large':
        'Вказане значення ширини більше за максимально доступне. Встановлено стандартне значення',
    'calculation.payload.height_is_too_large':
        'Вказане значення висоти більше за максимально доступне. Встановлено стандартне значення',
    'calculation.payload.bleeds_is_too_large':
        'Вказане значення відстані до краю стрічки більше за максимально доступне. Встановлено стандартне значення',
    'calculation.payload.paddings_is_too_large':
        'Вказане значення відстані між етикетками більше за максимально доступне. Встановлено стандартне значення',
    'calculation.payload.angle_corner_radius_is_too_large':
        'Вказане значення заокреглення кута більше за максимально доступне. Встановлено стандартне значення',
    'calculation.payload.width_is_too_small': 'Вказане значення ширини менше за мінімально доступне. Встановлено стандартне значення',
    'calculation.payload.height_is_too_small': 'Вказане значення висоти менше за мінімально доступне. Встановлено стандартне значення',
    'calculation.payload.bleeds_is_too_small':
        'Вказане значення відстані до краю стрічки менше за мінімально доступне. Встановлено стандартне значення',
    'calculation.payload.paddings_is_too_small':
        'Вказане значення відстані між етикетками менше за мінімально доступне. Встановлено стандартне значення',
    'calculation.payload.angle_corner_radius_is_too_small':
        'Вказане значення заокреглення кута менше за мінімально доступне. Встановлено стандартне значення',

    // WT-357:
    'product.is_custom_size_available': 'Доступний власний формат',
    'product.custom_size_prompt':
        'Ви можете встановити для даного товару "Власний формат", якщо у такого товару (або його видів) встановлена лише одна деталь(-і)',
    'product.custom_size_warning':
        'Ви не можете створити більше однієї деталі для товару чи його виду, доки для нього встановлено "Власний формат"',
    'product.custom_size.title': 'Параметри власного формату',
    'product.custom_size': 'Власний формат',
    'details.uncommon_detail_compability': '"Власний формат" несумісний з рулонними товарами та наліпками в аркушах',
    'details.static_format_compability':
        'Для товару встановлено "Власний формат". Обраний формат деталі буде застосовано, як стандартні дані під час розрахунку, які можна буде змінити',
    'product.list_labels_warning':
        'Ви не можете створити більше однієї деталі для товару чи його виду, якщо його деталь - це "Наліпка в аркушах"',
    'product.roll_warning':
        'Ви не можете створити більше однієї деталі для товару чи його виду, якщо його деталь друкується на рулонному прінтері',
    'details.labels_print_error':
        'Параметри "Рулонний товар" та "Наліпка в аркушах" недоступні, доки у товару чи його виду є принаймні одна створена деталь',

    // WT-1573:
    'product.does_not_require_production': 'Не потребує виготовлення',
    'order.product.comment_edit': 'Змінити коментар до товару',
    'order.product.comment_add': 'Додати коментар до товару',

    // WT-1597:
    'products.preview_assembly': 'Попередній перегляд збірки товару',
    'wizard.partial_mode.details_prompt': "Для відображення прев'ю збірки товару додайте принаймні одну деталь",
    'product.without_tc.has_no_details': 'Розрахунок вартості товару недоступний без деталей',
    'mockups.modal_material.prompt': 'Будь ласка, вкажіть параметри матеріалу для автоматичної генерації',
    'mockups.modal_material.work_area_prompt': 'Будь ласка, вкажіть параметри робочої області для автоматичної генерації',

    // WT-1502:
    'auth.user_not_found': 'Користувач з такими даними не знайдений. Будь ласка, перевірте введені вами дані на правильність',
    'auth.verify_error': 'Помилка верифікації користувача',

    // WT-1541:
    'typographies.invoice_warning': 'Для цієї друкарні рахунок не генерується',
    'typographies.generate_an_invoice': 'Генерувати рахунок',

    // WT-1518:
    'common.number_sm': 'число',
    'common.price_genitive_sm': 'ціни',
    'common.weight_genitive_sm': 'ваги',
    'common.time_genitive_sm': 'часу',
    'calcModes.formula.has_open_brackets': 'Неможливо зберегти формулу, для якої задано неспаровані дужки',
    'calcModes.formula.clone': 'Формулу успішно скопійовано у буфер обміну',
    'calcModes.formula.paste': 'Формулу успішно задано з буферу обміну',
    'calcModes.formula.prompt':
        'Для побудови формули скористайтесь операціями і функціями вище та змінними праворуч. Зверніть увагу, що історія формули не зберігає більше 20 кроків',
    'calcModes.formula.interactions': 'Використовуйте такі клавіші та їхні комбінації для маніпулювання редактором',
    'calcModes.formula.interactions_arrow_left': 'Перемістити курсор ліворуч',
    'calcModes.formula.interactions_arrow_right': 'Перемістити курсор праворуч',
    'calcModes.formula.interactions_backspace': 'Видалити попередній елемент',
    'calcModes.formula.interactions_delete': 'Видалити наступний елемент',
    'calcModes.formula.has_invalid_numbers': 'Застосовані у формулі числа мають бути натуральними або додатніми десятковими дробами',

    // WT-1574:
    'ready_products.write_off.action': 'Списати товар',
    'ready_products.write_off.title': 'Нове списання товару',
    'ready_products.write_off.is_not_available': 'Неможливо списати обраний товар',
    'stock.opeartion_type.write-off': 'Списання',

    // WT-1284:
    'domains.main_website_language': 'Основна мова веб-сайту',
    'typographies.domains.title': 'Домени друкарні',
    'common.is_main': 'Основний',
    'common.domain_sm': 'домен',
    'domains.main_domain_language': 'Основа мова домену',
    'domains.domain_languages': 'Мови домену',
    'domains.warning.default_lang_has_been_removed':
        'Увага! Перша доступна мова для цього домену була встановлена як основна, оскільки ви вимкнули попередню!',
    'domains.warning.has_not_available_languages': 'Не лишилося доступних мов. Неможливо створити ще один домен',
    'domains.warning.default_domain_has_been_removed':
        'Увага! Перший домен було встановлено, як основний, оскільки ви видалили попередній!',
    'domains.contact_language': 'Мова клієнта',
    'domains.contact_language_prompt': 'Обраною мовою клієнту надходитимуть листи на електронну пошту',

    // WT-1650:
    'library.algorithms.editing_prompt': 'Зміни в обраному алгоритмі транслюються на всі товари, що що його використовують',
    'librery.algorithms.components.modalSave.infoP1':
        'Послідовно обраний набір характеристик уточнює вибір конкретного виду матеріалу',
    'librery.algorithms.components.modalSave.infoP2':
        'Послідовно обраний набір характеристик уточнює вибір способу використання обладнання',

    // WT-1308:
    'products.has_not_roll_printing_module.error': 'Модуль "Рулонний друк" не підключений',
    'products.has_not_roll_printing_module.link': 'зверніться до спеціаліста з підтримки',

    // WT-1120:
    'library.works.material_formulas': 'Формули кількості витраченого матеріалу',
    'library.works.material_formulas.list': 'Аркушний друк',
    'library.works.material_formulas.roll': 'Рулонний друк',
    'calcModes.formula.variables_other.title': 'Інші',
    'calcModes.formula.variables_other.prompt': 'Додаткові системні змінні',
    'library.works.material_formulas.prompt': 'Формулу не вказано. Система використовуватиме стандартну',
    'library.works.material_formulas.set_fomula': 'Встановити формулу',
    'library.works.material_formulas.set_fomula.success': 'Стандартну формулу успішно встановлено. Для внесення змін збережіть роботу',
    'library.works.material_formulas.min_sheets_number': 'Мінімальна кількість матеріалу (аркуш)',
    'library.works.material_formulas.min_meters_number': 'Мінімальна кількість матеріалу (погон. м)',

    // WT-1604:
    'form.reason': 'Причина',
    'common.on_cancel_write_off': 'Скасувати списання',

    // WT-1688:
    'product.without_tc.has_details_without_format': 'Розрахунок вартості недоступний, доки для деталей не задано сталий формат',

    // WT-1703:
    'typography.settings.key_for': 'Ключ для',

    // WT-1145:
    'details.elements_amout': 'Кількість елементів',
    'details.elements_amout.fixed': 'Фіксована кількість',
    'details.elements_amout.custom': 'Довільна кількість',
    'details.elements_amout.only_even_values': 'Тільки парні значення',
    'common.min_count': 'Мін. к-сть',
    'common.max_count': 'Макс. к-сть',
    'common.pages_count': 'Кількість сторінок у деталі',
    'common.spreads_count': 'Кількість розворотів у деталі',
    'common.result_pages_count': 'В результаті кількість сторінок',
    'order_copy.tech_card_changed.prompt':
        'Увага! Технічна карта даного товару була змінена з моменту створення замовлення! Переконайтеся, що її нова конфігурація відповідає вашим вимогам',
    'order_copy.tech_card_changed.has_new_custom_count_details': 'Будь ласка, введіть нові параметри для повторення замовлення',
    'order_copy.tech_card_changed.min_count_prompt':
        'Технічну картку для товару {{product}} було змінено. Старе значення кількості елементів у деталі {{title}} менше за мінімальне. Кількість елементів для цієї деталі було змінено на {{count}}',
    'order_copy.tech_card_changed.max_count_prompt':
        'Технічну картку для товару {{product}} було змінено. Старе значення кількості елементів у деталі {{title}} більше за максимальне. Кількість елементів для цієї деталі було змінено на {{count}}',
    'common.pages_count_default': 'Кількість сторінок',
    'files.leave_the_block_blank': 'Порожній блок',
    'files.mockup_is_not_required': 'Макет не потрібен',
    'tech_card.elements_in_detail_by_for_item': 'Елементів на примірник',

    // WT-1558:
    'library.equipments.use_rapports': 'Використання раппортів',
    'library.equipments.rapport': 'Раппорт',
    'library.equipments.rapport_sm': 'раппорт',
    'library.equipments.rapport.amount_gear_teeth': 'Кількість зубів',
    'library.equipments.rapport.frame_height': 'Висота кадру',
    'library.equipments.rapports_error': 'Обладнання, яке використовує раппорти, має містити хоча б один раппорт',
    'calculation.flexo_print_prompt': "Відстань між наліпками була змінена на {{padding}} у зв'язку з технічними особливостями товару",
    'calculation.flexo_print.product_padding_info':
        'Для виробництва цього товару буде застосовано обладнання з раппортами. Редагування неможливе',

    // WT-1692:
    'tasks.booked_materials': 'Зарезервовані',
    'tasks.written_off_materials': 'Списані',

    // WT-1563:
    'orders.product_manufacturing_costs': 'Вартість виготовлення товару',
    'orders.products_manufacturing_costs': 'Вартість виготовлення товарів',
    'kind.logs.general_manufacturing_costs': 'Загальна сума витрат',
    'common.probable_income': 'Імовірний дохід',
    'kind.logs.operations.product_kind_price': 'Ціна',

    // WT-1696:
    'files.mockup_processing_error': 'Помилка обробки макету',

    // WT-1674:
    'operations.activity': 'Активність',
    'operations.logs.no_data': 'Над товаром не проводилося жодних дій...',
    'operations.logs.assignee_title': 'призначив виконавця',
    'operations.logs.status_title': 'змінив статус задачі',
    'operations.logs.comment_title': 'коментар',
    'operations.logs.consumption_title': 'провів списання матеріалу',
    'operations.logs.custom_title': 'створив ручну задачу',
    'operations.logs.relation.to_operation': 'задачі',
    'operations.logs.relation.to_orderProduct': 'товару',
    'operations.logs.relation.to_order': 'замовлення',
    'common.add_sm': 'додав',
    'common.edit_sm': 'редагував',
    'common.delete_sm': 'видалив',
    'common.edited': 'Ред.',
    'common.in_amount_sm': 'у кількості',
    'preposition.to': 'до',
    'preposition.for': 'для',
    'preposition.in': 'у',
    'preposition.at': 'о',

    // WT-1704:
    'permissions.front_librarySettings.title': 'Налаштування ТК',

    // WT-1758:
    'common.no_name': 'Безіменний',
    'common.dates': 'Дати',
    'common.order_with_number': '№ Замовлення',
    'common.update_list': 'Оновити список',
    'operations.table.button_actions': 'Дії задачі',
    'operations.action.to_mockups': 'Перейти до макетів',
    'operations.action.download_tech_card': 'Завантажити технічну картку',
    'operations.action.production_operations': 'Задачі виробництва',
    'operations.action.production_comments': 'Коментарі виробництва',
    'operations.action.production_logs': 'Історія виробництва',
    'operations.production_parameters': 'Параметри для виготовлення',
    'operations.action.send_message': 'Залишити коментар',
    'mockups.processing_error': 'Виникла(-и) помилка(-и) під час обробки макету!',

    // WT-725:
    'common.role_templates': 'Шаблонні ролі',
    'common.typography_roles': 'Власні ролі',
    'common.reporting': 'Звітність',
    'menu.typographies.owners': 'Власники',
    'menu.typographies.doppler': 'Працівники',
    'menu.role_templates': 'Шаблони ролей',
    'employees.druk_index': 'Працівники DRUK.ua',
    'employees.doppler_index': 'Працівники друкарень',
    'employees.role_save.template_title': 'Зміна шаблону ролі',
    'employees.role_save.template_prompt': 'Ви внесли зміни до існуючого шаблону ролі',
    'employees.role_save.template.change_current': 'Застосувати зміни до існуючого шаблону ролі',
    'employees.role_save.template.create_new': 'Створити новий шаблон з внесеними змінами',
    'owners.title': 'Власники друкарень',
    'owner.tilte': 'Власник друкарні',
    'owners.owner_genitive_sm': 'власника друкарні',
    'roles.role_permissions': 'Доступи ролі',
    'roles.role_without_permissions_prompt': 'Для цієї ролі не задано доступи!',
    'role_templates.item_title': 'Шаблон ролі',
    'role_templates.template_genitive_sm': 'шаблон ролі',
    'roles.creation.template_prompt':
        'Ви можете створити роль на основі базового шаблону. Для цього достатньо обрати шаблон у полі вище. Всі необхідні доступи будуть проставлені автоматично',

    // WT-1757:
    'filter.active_options': 'Застосовані',
    'filter.inactive_options': 'Невикористані',
    'common.this_month': 'Цей місяць',
    'common.last_month': 'Попередній місяць',
    'common.this_year': 'Цей рік',

    // WT-1633:
    'employees.employee_is_deleted': 'Деактивований працівник',
    'employees.on_activate_employee': 'Активувати працівника',

    // WT-1835:
    'typography.ga.keys_title': 'GA ключі',
    'typography.ga.keys_prompt': 'Ви можете зберігати для кожного домену власний ключ або дублювати його',

    // WT-1633
    'auth.employee_is_deactivated':
        'Вибачте, для вас доступ закритий. Для відновлення доступу - зверніться до адміністратора друкарні',

    // WT-1840:
    'params.dependencies': 'Залежності параметру',
    'common.material_sm_genitive': 'матеріалу',
    'common.equipment_sm_genitive': 'обладнання',
    'equipment_params.deps.kinds_with_hidden_formula_prompt':
        'Нижче наведені режими роботи обладнання, для розрахунку яких раніше використовувалися формули. Щоб очисти їх, використовуйте кнопки біля посилань',
    'calcModes.reset_formula': 'Скинути формулу',
    'delete.material_variable_used': 'Параметр використовується у формулах робіт та/або видів матеріалів',
    'delete.equipment_variable_used': 'Параметр використовується у формулах робіт та/або режимів роботи обладнання',

    // WT-1859:
    'maket.circuit': 'Контур',
    'production.mockup': 'Макет',

    // WT-273:
    'common.update': 'Оновити',
    'dashboard.title': 'Дашборд',
    'dashboard.data_is_current_on': 'Дані актуальні на',
    'dashboard.period_filter.title': 'Період',
    'dashboard.period_filter.today': 'За сьогодні',
    'dashboard.period_filter.last_week': 'За останні 7 днів',
    'dashboard.period_filter.last_month': 'За останні 30 днів',
    'dashboard.period_filter.last_year': 'За останні 365 днів',
    'dashboard.orders.base_chart.title': 'Статистика замовлень',
    'dashboard.orders.base_chart.total': 'Кількість замовлень',
    'dashboard.orders.base_chart.total_price': 'Загальна сума',
    'dashboard.orders.base_chart.avg_price': 'Середній чек',
    'dashboard.orders.base_chart.total_manufacturing_costs': 'Маржинальність',
    'dashboard.orders.status_chart.title': 'Статистика замовлень за статусами',
    'dashboard.orders.status_stacked_chart.title': 'Співвідношення статусів замовлень за джерелом',
    'dashboard.orders.status_polar_chart.title': 'Співвідношення замовлень за статусом',
    'dashboard.orders.source_chart.title': 'Кількість нових замовлень за джерелом',
    'dashboard.orders.source_chart.created_by_client': 'Створені на сайті',
    'dashboard.orders.source_chart.created_by_manager': 'Створені менеджером',
    'dashboard.clients.base_chart.title': 'Статистика клієнтів',
    'dashboard.clients.base_chart.total': 'Кількість клієнтів',
    'dashboard.clients.base_chart.multiple_orders': 'Клієнти з повторним замовленням',
    'dashboard.clients.base_chart.negative_balance': 'Боржники',
    'dashboard.clients.base_chart.single_order': 'Клієнти з одним замовленням',
    'dashboard.clients.base_chart.without_orders': 'Сплячі клієнти',
    'dashboard.products.popularity_chart.title': 'Топ популярних товарів',
    'dashaboard.prompt.in_development': 'У розробці',

    // WT-1407:
    'employees.deactivation_confirmation_title': 'Підтвердження деактивації працівника',
    'employees.deactivation_confirmation_prompt': 'Зверніть увагу, що працівнику {{employee}} призначено:',
    'employees.deactivation_confirmation_prompt.clients': 'клієнтів',
    'employees.deactivation_confirmation_prompt.orders': 'замовлень',
    'employees.deactivation_confirmation_prompt.operations': 'задач',
    'employees.deactivation_confirmation_action': 'Деактивувати',

    // WT-1963:
    'developer_page.title': 'Сторінка розробника DRUK.ua',
    'developer_page.components': 'Компоненти',
    'developer_page.info.component': 'Компонент',
    'developer_page.info.component_button': 'Кнопка',
    'developer_page.info.component_icon_button': 'Кнопка-значок',
    'developer_page.info.component_icon_toggle_button': 'Кнопка-перемикач',
    'developer_page.info.type': 'Тип',
    'developer_page.info_type_filled': 'Заповнений(-а) (filled)',
    'developer_page.info_type_tonal': 'Тональний(-а) (tonal)',
    'developer_page.info_type_elevated': 'Плаваючий(-а) (elevated)',
    'developer_page.info_type_outlined': 'З обведенням (outlined)',
    'developer_page.info_type_text': 'Текстовий(-а) (text)',
    'developer_page.info_type_standard': 'Стандартний(-а) (standard)',
    'developer_page.info_state': 'Стан',
    'developer_page.info_state_enabled': 'Увімкнений(-а)',
    'developer_page.info_state_enabled_disactive': 'Увімкнений(-а) + неактивний(-а)',
    'developer_page.info_state_enabled_active': 'Увімкнений(-а) + активний(-а)',
    'developer_page.info_state_waiting': 'Очікує',
    'developer_page.info_state_disabled': 'Вимкнений(-а)',
    'developer_page.info_state_disabled_disactive': 'Вимкнений(-а) + неактивний(-а)',
    'developer_page.info_state_disabled_active': 'Вимкнений(-а) + активний(-а)',

    // WT-1868:
    'common.color_theme.title': 'Невимовно секретна логіка',
    'common.color_theme.alternative_logo': 'Альтернативний стиль логотипу',
    'common.color_theme.alternative_logo_prompt': 'Увімкнути альтернативний логотип',
    'common.color_theme.contrast_drawer': 'Інверсивний стиль навігації',
    'common.color_theme.contrast_drawer_prompt': 'Підвищити контрастність меню навігації',
    'common.color_theme.dark_theme': 'Увімкнути темний бік',
    'common.color_theme.dark_theme_prompt': 'Темна тема для DRUK.Engine',

    // WT-1852
    'library.works.material_formula': 'Формула кількості витраченого матеріалу',
    'library.works.material_formulas.min_material_amount': 'Мінімальна кількість матеріалу',
    'library.works.material_formulas.standard_formulas': 'Стандартні формули',
    'library.works.material_formulas.sheets_formula': 'Формула розрахунку к-сті аркушного матеріалу',
    'library.works.material_formulas.roll_formula': 'Формула розрахунку к-сті рулонного матеріалу',
    'common.formula_info': 'Довідка',

    // WT-1949
    'pricing_plan.title': 'План підписки',
    'pricing_plan.employees_limit': 'Ліміт працівників',
    'pricing_plan.unlimited': 'Необмежений',
    'pricing_plan.limit_prompt.is_reached': 'Ви досягли ліміту',
    'pricing_plan.limit_prompt.contact_the_admin': 'Зверніться до адміністратора',
    'pricing_plan.limit_prompt.creation_unavailable': 'Неможливо створити {{target}}',
    'pricing_plan.limit_prompt.activtion_unavailable': 'Неможливо відновити {{target}}',
    'pricing_plan.limit_prompt.target.employee': 'працівника',

    // WT-1915:
    'library.works.setup_material_formula': 'Формула кількості матеріалу на приладку',
    'material.recommendations.setup_material_amount': 'Кількість матеріалу на приладку',

    // WT-1799
    'menu.contacts': 'Контакти',
    'menu.companies': 'Компанії',
    'companies.creation_btn': 'Створити компанію',
    'companies_item.header': 'Компанія',
    'companies_item.new': 'Нова компанія',
    'companies_item.form_bic': 'BIC-код',
    'clients.natural_entity': 'Фізична особа',
    'clients.natural_entity_short': 'Фіз. особа',
    'clients.legal_entity': 'Юридична особа',
    'clients.legal_entity_short': 'Юр. особа',
    'clients.responsible_person': 'Відповідальна особа',
    'companies.prompt.item_was_not_found':
        'Компанія з такою назвою не була знайдена. Перевірте правильність написання, або створіть нову компанію у разі необхідності',
    'status.not_assigned_fem': 'Не призначена',
    'companies.filters_trashed.label': 'Відображати',
    'companies.filters_trashed.with_trashed': 'З видаленими',
    'companies.filters_trashed.without_trashed': 'Тільки активні',
    'status.active_fem': 'Активна',
    'status.deleted_fem': 'Видалена',
    'common.restore': 'Відновити',
    'companies.restore_prompt':
        "За компанією, яку ви збираєтеся відновити, закріплено {{count}} осіб. Оберіть тих, кого хочете повторно прив'язати до цієї компанії. Решта осіб буде відв'язана назавжди",
    'clients.legal_entity.empty_company_prompt':
        "Обраний користувач є першою особою, яка пов'язана з вказаною компанією. Така особа автоматично стає відповідальною за компанію",
    'create_order.placing.empty_company_prompt':
        "За обраною компанією не закріплена жодна контактна особа. Для продовження прив'яжіть до компанії контактну особу",
    'companies.appoint_entity': "Прив'язати контактну особу",
    'companies.create_entity_prompt': 'Ви можете скористатися пошуком клієнтів або створити нового',
    'common.appoint': "Прив'язати",

    // WT-1914:
    'settings.product.default_indent': 'Стандартний відступ до краю стрічки (мм)',
    'settings.product.min_indent': 'Мінімальний відступ до краю стрічки (мм)',
    'settings.product.max_indent': 'Максимальний відступ до краю стрічки (мм)',
    'settings.product.core_diameters': 'Варіанти діаметру втулки (мм)',
    'settings.product.default_roll_diameter': 'Стандартний діаметр рулону (мм)',
    'settings.product.min_roll_diameter': 'Мінімальний діаметр рулону (мм)',
    'settings.product.max_roll_diameter': 'Максимальний діаметр рулону (мм)',
    'settings.product.roll_diameters': 'Варіанти діметру рулону (мм)',
    'settings.product.standard_settings_are_used': 'Використовуються стандартні налаштування',
    'products.roll_parameters.title': 'Параметри рулонного друку',
    'products.winding_direction.outside': 'Назовні',
    'products.winding_direction.inside': 'Всередину',
    'calculation.product.indent_left': 'Лівий відступ до краю стрічки',
    'calculation.product.indent_right': 'Правий відступ до краю стрічки',
    'calculation.product.padding': 'Відстань між наліпками',
    'calculation.product.core_diameter': 'Діаметр втулки',
    'calculation.product.roll_diameter': 'Діаметр рулону',
    'calculation.product.roll_diameter.use_custom_roll_diameter': 'Власний діаметр рулону',
    'calculation.product.winding_direction': 'Тип перемотки',
    'form.select_tagging_prompt': 'Для створення нового значення впишіть його у поле та натисніть відповідну кнопку',

    // WT-1691:
    'common.modify': 'Модифікувати',
    'create_order.alg_modify.title': 'Модифікувати алгоритм',
    'create_order.alg_modify.original_alg': 'Оригінальний алгоритм',
    'create_order.alg_modify.processing_propmt': 'Триває обробка алгоритму',
    'create_order.alg_modify.modify_propmt': 'Триває модифікація технічної картки',
    'create_order.alg_modify.modified_alg_used': 'Застосовано модифікований алгоритм',
    'create_order.alg_modify.modify_tag': 'Модифікація',
    'calculation.logs.winner': 'Виграшний алгоритм',

    // WT-1867:
    'create_order.basket_price_error': 'Ціна не може становити 0 або нижче',

    // WT-2023:
    'process.usage_types.title': 'Тип використання матеріалу',
    'process.usage_types.own_material': 'Власний матеріал',
    'process.usage_types.other_process_material': 'Матеріал з іншого процесу',
    'process.usage_types.dependent_material': 'Залежний матеріал',
    'process.usage_types.own_material.support': 'Матеріал списуватиметься зі складу',
    'process.usage_types.other_process_material.support':
        'Процес обирається в редакторі алгоритму, матеріал не буде списуватись зі складу',
    'process.usage_types.dependent_material.support':
        'Розрахунок власного матеріалу спирається на кількість матеріалу з іншого процесу, процес обирається в редакторі алгоритму',
    'process.formulas.has_other_process_material_usage_type_prompt':
        'Дана формула не використовується для обрахунку у процесах, що використовують матеріал з інших процесів',
    'algorithms.process.use_related_process_material':
        'Оберіть попередній процес, параметри матеріалу з якого будуть використовуватися у поточному',

    // WT-1807:
    'pages.management.title.flexible_pages': 'Підключення власних сторінок',
    'pages.management.permanent_pages': 'Перманентні сторінки',
    'pages.management.permanent_pages.prompt': "Перелічені сторінки є обов'язковими для роботи сайту і не можуть бути вимкненими",
    'pages.management.flexible_pages': 'Налаштовувані сторінки',
    'pages.management.flexible_pages.prompt':
        'Ви можете керувати наведеними сторінками нижче, вмикаючи чи вимикаючи їх для вашого сайту',
    'pages.is_unavailable_prompt': 'Сторінка недоступна',
    'pages.own_pages': 'Власні сторінки',
    'algorithms.process.has_no_related_process_material':
        'Зверніть увагу, параметри матеріалу з обраного процесу використовуються у розрахунках поточного процесу',

    // WT-1940:
    'stock.material_cut_is_unavailable': 'Недостатньо кількості для здійснення порізки',

    // WT-1979:
    'typographies.validator_alpabets.client_names': 'Абетки валідації імен клієнтів',
    'typographies.validator_alpabets.prompt': 'Символи з обраних абеток будуть доступними для імен клієнтів у системі та на веб-сайті',
    'typographies.validator_alpabets.cyrillic_sm_genitive_fem': 'кириличної',
    'typographies.validator_alpabets.latin_sm_genitive_fem': 'латинської',
    'typographies.validator_alpabets.georgian_sm_genitive_fem': 'грузинської',

    // WT-1918:
    'typography.delivery.title': 'Доступні варіанти доставки',
    'typography.delivery.providers_title': 'Провайдери',
    'typography.delivery.option_provider': 'Провайдер',
    'typography.delivery.option_price': 'Вартість',
    'typography.delivery.option_payer': 'Платник',
    'typography.delivery.default_option_prompt':
        'Цей варіант доставки під час створення замовлення буде автоматично закріплюватись за клієнтами, у яких немає жодного способу доставки',
    'typography.delivery.can_not_be_default_prompt': 'Тільки опублікований варіант доставки може використовуватися, як стандартний',
    'typography.delivery.option_provider.prompt':
        'Цей варіант доставки недоступний, оскільки модуль <b>{{module}}</b> вимкнений для вашої друкарні',
    'typography.delivery.option_provider.delivery_type_prompt':
        'Не можна змінити спосіб доставки для цього варіанту доставки, оскільки він підключений до провайдера',
    'typography.delivery.option_payer.is_typography': 'Сплачує друкарня',
    'typography.delivery.dpd_baltic.search_returned_no_results': 'Відділень не знайдено',
    'clients.delivery.last_address': 'Остання адреса',
    'clients.delivery.option_title': 'Варіант доставки',
    'clients.delivery.has_no_addresses_prompt': 'Список адрес порожній',
    'clients.delivery.has_no_available_options': 'Немає опублікованих варіантів доставки для обраного типу',
    'clients.delivery.is_existed_pickup_option_picked': 'Така адреса самовивозу вже закріплена за обраним клієнтом',
    'clients.delivery.selected_address_has_no_available': 'Зверніть увагу, що варіант доставки для обраної адреси більше недоступний',
    'create_order.delivery.has_no_available_delivery_options':
        'У системі не існує опублікованих варіантів доставки. Створити замовленння неможливо',
    'create_order.delivery.auto_address_create_was_success':
        'За обраним клієнтом не було закріплено жодного способу доставки. Автоматично створено новий спосіб доставки',
    'create_order.delivery.failed_to_auto_create_address':
        'Не вдалося автоматично створити спосіб доставки для обраного клієнта. Будь ласка, створіть новий спосіб вручну',
    'create_order.finish.delivery_price': 'Вартість доставки',
    'copy_order.old_delivery_is_unavailable_prompt':
        'Спосіб доставки з обраного замовлення більше недоступний. Будь ласка, оберіть інший або створіть новий',
    'common.is_standard_f': 'Стандартна',
    'common.delivery_sm_genitive': 'доставку',
    'common.show_more': 'Показати більше',
    'common.show_less': 'Показати менше',

    // WT-2031:
    'materials.kind_without_params': 'Параметри не вказані',
    'stock.internal_cut.remnant_after_cut': 'Залишок після порізки',
    'stock.internal_cut.add_new_format': 'Додати матеріал для порізки',
    'stock.internal_cut.rolls_amount': 'Кількість рулонів',

    // WT-2079:
    'product.has_been_modified': 'Модифікований',

    // WT-2087:
    'clients.note': 'Примітка',

    // WT-1616:
    'calculation.logs.has_no_formula': 'Формула відсутня',
    'calculation.logs.debug_type.by_operation': 'За процесом',
    'calculation.logs.debug_type.by_variable': 'За змінною',
    'calculation.logs.angorithm_has_logs': 'Алгоритм містить помилки',
    'calculations.logs.detail_sm_genitive': 'деталей',

    // WT-1693:
    'formats.filters.contour.title': 'Наявність контуру',
    'formats.filters.contour.has_contour': 'З контуром',
    'formats.filters.contour.has_no_contour': 'Без контуру',
    'formats.contour.file': 'Файл контуру порізки',
    'formats.contour.file_prompt': 'Максимальний розмір файлу – 10мб. Формат файлу – *.pdf',
    'format.download_contour': 'Завантажити контур',
    'format.delete_contour': 'Видалити контур',
};
