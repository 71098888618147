<template>
    <div class="wrapper">
        <div class="wrapper__form">
            <router-view></router-view>
        </div>

        <div
            class="wrapper__jumbotron"
            :style="{
                'background-image': 'url(' + require('@img/login_picture.svg') + ')',
            }" />
    </div>
</template>

<script>
    export default {
        name: 'login-layout',
    };
</script>

<style scoped lang="scss">
    .wrapper {
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        &__form {
            width: 40%;
            // background-color: $c_white;
            @media (max-width: $druk-breakpoints-md) {
                width: 100%;
            }
        }
        &__jumbotron {
            display: flex;
            flex-direction: column;
            width: 60%;
            height: 100vh;
            // background-color: #c4c4c4;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 100%;
            @media (max-width: $druk-breakpoints-md) {
                display: none;
            }
        }
    }
</style>
