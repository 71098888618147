import Vue from 'vue';

import wizard from './wizard';

export default {
    namespaced: true,

    modules: {
        wizard,
    },

    state: {
        list: [],
        listPagination: {},

        rootList: [],
        rootPagination: {},

        item: {},
        rootItem: {},

        sortList: [],
        nestedCategories: [],
        nestedProducts: [],

        // @TODO: This is not a typical module, maybe like Knowledge. It has not a limit key - we need to throw all data for user. I think that we need to create common 'types' for modules in the future
        listFilter: {
            page: null,
            limit: null,
            title: null,
            is_published: null,
            parent_id: null,
            sort: 'sort',
            direction: 'asc',
        },

        formData: {},

        loadingGetList: false,
        loadingGetRootList: false,
        loadingGetSortList: false,
        loadingGetNestedCategories: false,
        loadingGetNestedProducts: false,
        loadingGetItem: false,
        loadingGetRootItem: false,
        loadingSaveItem: false,
        loadingSortList: false,
        loadingMoveCategories: false,
        loadingMoveProducts: false,
        loadingDeleteItem: false,
        loadingDeleteList: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        expandedList(state) {
            return [...state.list].map((item) =>
                item.parent
                    ? Object.assign(item, {
                          prompt: 'parent',
                          promptPayload: 'common.category',
                      })
                    : item,
            );
        },

        expandedRootList(state) {
            return [...state.rootList].map((item) =>
                item.parent
                    ? Object.assign(item, {
                          prompt: 'parent',
                          promptPayload: 'common.category',
                      })
                    : item,
            );
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_ROOT_LIST(state, list) {
            state.rootList = list;
        },

        SET_ROOT_PAGINATION(state, pagination) {
            state.rootPagination = pagination;
        },

        SET_SORT_LIST(state, list) {
            state.sortList = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_ROOT_ITEM(state, item) {
            state.rootItem = item;
        },

        SET_LIST_NESTED(state, object) {
            let list = object.has_nested ? state.nestedCategories : state.list,
                target = list.find((item) => item.id === object.id),
                index = list.indexOf(target);

            if (!target) return;

            Vue.set(list, index, Object.assign(target, object.data));
        },

        SET_NESTED_CATEGORIES(state, list) {
            state.nestedCategories = list;
        },

        SET_NESTED_PRODUCTS(state, list) {
            state.nestedProducts = list;
        },

        SET_FORM_DATA(state, formData) {
            Vue.set(state, 'formData', formData);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_LIST_FILTER(state, newFilterObj) {
            $fn.forIn(newFilterObj, (val, key) => {
                Vue.set(state.listFilter, key, val);
            });

            Vue.set(state.listFilter, 'page', newFilterObj.page || 1);
        },

        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: null,
                title: null,
                is_published: null,
                parent_id: null,
                sort: 'sort',
                direction: 'asc',
            };
        },

        RESET_ITEM(state) {
            state.item = {};
        },

        RESET_FORM_DATA(state) {
            state.formData = {};
        },
    },

    actions: {
        GET_LIST: async ({ state, rootState, getters, rootGetters, commit }, filters = {}) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = rootGetters.hasTypographyAccess
                        ? `/api/typographies/${rootGetters.currentTypography.id}/catalog`
                        : `/api/catalog`,
                    currentFilters = Object.keys(filters).length ? filters : getters.listActiveFilter;

                let resp = await $axios.get(path, {
                    params: currentFilters,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ROOT_LIST: async ({ state, rootState, getters, rootGetters, commit }, filters) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetRootList',
                    status: true,
                });

                let path = `/api/catalog`;

                let resp = await $axios.get(path, {
                    params: filters,
                });

                commit('SET_ROOT_LIST', resp.list);
                commit('SET_ROOT_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetRootList',
                    status: false,
                });
            }
        },

        GET_SORT_LIST: async ({ state, rootState, getters, rootGetters, commit }, payload = { filters: {} }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetSortList',
                    status: true,
                });

                let path = rootGetters.hasTypographyAccess
                        ? `/api/typographies/${rootGetters.currentTypography.id}/catalog`
                        : `/api/catalog`,
                    payloadParams = rootGetters.hasTypographyAccess
                        ? $fn.assign(payload.filters, { is_published: 1 })
                        : payload.filters;

                let resp = await $axios.get(path, {
                    params: $fn.assign(payloadParams, {}),
                });

                commit('SET_SORT_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetSortList',
                    status: false,
                });
            }
        },

        GET_NESTED_CATEGORIES: async ({ state, getters, rootGetters, commit }, { id, filters }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetNestedCategories',
                    status: true,
                });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/catalog`
                    : `/api/catalog`;

                let resp = await $axios.get(path, {
                    params: Object.assign({ parent_id: id }, filters),
                });

                commit('SET_LIST_NESTED', {
                    id,
                    data: { nested: { children: resp.list } },
                });
                commit('SET_NESTED_CATEGORIES', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetNestedCategories',
                    status: false,
                });
            }
        },

        GET_NESTED_PRODUCTS: async ({ state, getters, rootGetters, commit }, { id, filters, has_nested }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetNestedProducts',
                    status: true,
                });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/products`
                    : `/api/products`;

                let resp = await $axios.get(path, {
                    params: Object.assign({ 'catalog-id': id }, filters),
                });

                commit('SET_LIST_NESTED', {
                    id,
                    has_nested,
                    data: { nested: { products: resp.list } },
                });
                commit('SET_NESTED_PRODUCTS', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetNestedProducts',
                    status: false,
                });
            }
        },

        GET_ITEM: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/catalog/${id}`
                    : `/api/catalog/${id}`;

                let resp = await $axios.get(path);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        GET_ROOT_ITEM: async ({ state, getters, rootGetters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetRootItem',
                    status: true,
                });

                let path = `/api/catalog/${id}`;

                let resp = await $axios.get(path);

                commit('SET_ROOT_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingGetRootItem',
                    status: false,
                });
            }
        },

        SAVE_ITEM: async ({ state, rootGetters, commit, dispatch }, { formData, parent_id }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/catalog`
                    : `/api/catalog`;

                let resp = await $axios.post(path, formData);

                parent_id ? dispatch('GET_LIST', { parent_id: parent_id }) : dispatch('GET_LIST');

                if (formData.id && Object.keys(state.item).length) dispatch('GET_ITEM', formData.id);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        SORT_LIST: async ({ state, rootGetters, commit, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSortList',
                    status: true,
                });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/catalog/sort`
                    : `/api/catalog/sort`;

                let resp = await $axios.post(path, formData);

                dispatch('GET_SORT_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSortList',
                    status: false,
                });
            }
        },

        MOVE_CATEGORIES: async ({ commit, rootGetters, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingMoveCategories',
                    status: true,
                });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/catalog/move-catalog`
                    : `/api/catalog/move-catalog`;

                let resp = await $axios.post(path, formData);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingMoveCategories',
                    status: false,
                });
            }
        },

        MOVE_PRODUCTS: async ({ commit, rootGetters, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingMoveProducts',
                    status: true,
                });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/catalog/move-products`
                    : `/api/catalog/move-products`;

                let resp = await $axios.post(path, formData);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingMoveProducts',
                    status: false,
                });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, { id, parent_id, force }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/catalog/${id}?force=${+!!force}`
                    : `/api/catalog/${id}`;

                let resp = await $axios.delete(path);

                if (resp.success) parent_id ? dispatch('GET_LIST', { parent_id }) : dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },

        DELETE_LIST: async ({ commit, rootGetters, dispatch }, formData) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingDeleteList',
                    status: true,
                });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters[`currentTypography`].id}/catalog/delete`
                    : `/api/catalog/delete`;

                let resp = await $axios.delete(path, {
                    data: formData,
                });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingDeleteList',
                    status: false,
                });
            }
        },

        EXPORT_LIST: async ({ state, commit, rootGetters, dispatch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get(`/api/catalog/export`).then((resp) => {
                    const url = resp.link;
                    const link = document.createElement('a');
                    link.href = url;
                    link.click();

                    URL.revokeObjectURL(link.href);
                });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },
    },
};
