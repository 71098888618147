<template>
    <div
        class="formula__variable is-input"
        :class="{
            'has-icon': item.value.icon,
            'has-caret': hasActiveCaret,
            'has-error': hasError,
        }"
        @click="onToggle">
        <span class="formula__number">
            <label class="formula__char" :class="{ 'has-caret': caret === 0 }"></label>

            <template v-if="area[item.index].value.input">
                <label
                    v-for="(char, index) in area[item.index].value.input.split('')"
                    :key="index"
                    class="formula__char"
                    :class="{ 'has-caret': index === caret - 1 }"
                    >{{ char }}</label
                >
            </template>

            <label v-else class="formula__placeholder">{{ 0 }}</label>
        </span>

        <input
            v-model="area[item.index].value.input"
            ref="input"
            class="formula__input"
            :data-vv-name="alias"
            :data-vv-as="alias"
            v-validate="'float'"
            @input="onInput"
            @keydown="onKeyDown"
            @focus="onFocus"
            @blur="onBlur" />
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    export default {
        name: 'calc-formula-number',

        inject: ['$validator'],

        props: {
            item: Object,
        },

        data() {
            return {
                caret: null,
            };
        },

        computed: {
            ...mapState({
                LEFT_ARROW_KEY: (state) => state.constant.LEFT_ARROW_KEY,
                TOP_ARROW_KEY: (state) => state.constant.TOP_ARROW_KEY,
                RIGHT_ARROW_KEY: (state) => state.constant.RIGHT_ARROW_KEY,
                BOTTOM_ARROW_KEY: (state) => state.constant.BOTTOM_ARROW_KEY,

                area: (state) => state.calcModes.formula.area,
                version: (state) => state.calcModes.formula.version,
            }),
            ...mapGetters({
                currentArea: 'calcModes/formula/currentArea',
            }),

            alias() {
                return `formula-number-${$fn.generateHash()}`;
            },

            hasActiveCaret() {
                return this.caret !== null;
            },

            hasError() {
                return this.errors.has(this.alias);
            },

            hasNewValue() {
                return this.currentArea[this.item.index].value.input !== this.area[this.item.index].value.input;
            },
        },

        methods: {
            ...mapMutations({
                SET_VERSION: 'calcModes/formula/SET_VERSION',
            }),

            onToggle() {
                this.hasActiveCaret ? this.$refs.input.blur() : this.$refs.input.focus();
            },

            onFocus() {
                this.$bus.$emit('on-hide-empty-cursor');

                this.onSetCaret(this.item.value.input?.length || 0);

                this.$emit('resetCursor');
                this.$emit('setCursor');
            },

            onBlur() {
                this.$bus.$emit('on-show-empty-cursor');

                if (this.hasNewValue) {
                    this.$bus.$emit('on-area-update');

                    this.SET_VERSION({
                        value: this.version + 1,
                        is_update: true,
                    });
                }

                this.onSetCaret(null);
                this.$emit('setCursor');
            },

            onInput(e) {
                this.onSetCaret(e.target.selectionEnd);
            },

            onKeyDown(e) {
                if (e.keyCode === this.LEFT_ARROW_KEY && this.caret > 0) this.onSetCaret(this.caret - 1);
                if (e.keyCode === this.TOP_ARROW_KEY) this.onSetCaret(0);
                if (e.keyCode === this.RIGHT_ARROW_KEY && this.caret < e.target._value?.length) this.onSetCaret(this.caret + 1);
                if (e.keyCode === this.BOTTOM_ARROW_KEY) this.onSetCaret(e.target._value?.length || 0);
            },

            onSetCaret(value) {
                this.caret = value;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .formula {
        &__placeholder {
            color: var(--druk-on-surface-variant);
            // opacity: 0.5;
        }
    }
</style>
