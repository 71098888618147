var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"selector selector--input",class:{
            'has-focus': _vm.hasActive,
            'has-full': _vm.hasFull,
            'has-error': _vm.errors.has(_vm.name) || _vm.hasMaxLengthError,
            'has-loading': _vm.loader,
        }},[_c('div',{staticClass:"selector__label",class:{ [`druk-l-surface-${_vm.surface}`]: _vm.surface },on:{"click":_vm.onToggle}},[_c('span',[_c('label',[_vm._v(_vm._s(_vm.label))]),(_vm.hasRequired)?_c('i',[_vm._v("*")]):_vm._e()]),(_vm.hint)?_c('druk-hint',{attrs:{"tooltip":{ text: _vm.hint.text || _vm.hint, from: 'top', maxWidth: _vm.hint.maxWidth, isNotCollapse: _vm.hint.isNotCollapse },"icon":{ name: 'circle-info', size: 'xs', color: _vm.hasActive ? 'primary' : 'outline' }}}):_vm._e()],1),_c('div',{staticClass:"selector__main",class:{ [`druk-l-surface-${_vm.surface}`]: _vm.surface }},[(_vm.beforeText)?_c('div',{staticClass:"selector__beforeText",on:{"click":_vm.onToggle}},[_vm._v(" "+_vm._s(_vm.beforeText)+" ")]):_vm._e(),(_vm.type === 'number')?[_c('input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.rules || ''),expression:"rules || ''"}],key:_vm.name,ref:"area",staticClass:"selector__area",class:{
                        'has-password': _vm.type === _vm.TYPE_PASSWORD,
                        'has-before': _vm.beforeText,
                        'has-after': _vm.afterText,
                    },attrs:{"step":"any","data-vv-name":_vm.name,"data-vv-as":_vm.label,"placeholder":typeof _vm.placeholder === 'String' ? _vm.placeholder : null,"type":"number"},domProps:{"value":_vm.value},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('enter')},function($event){return _vm.$emit('keyup')}],"input":function($event){return _vm.$emit('input', $event.target.value)}}})]:(_vm.type === 'integer')?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.count),expression:"count"},{name:"validate",rawName:"v-validate",value:(_vm.rules || ''),expression:"rules || ''"}],key:_vm.name,ref:"area",staticClass:"selector__area",class:{
                        'has-password': _vm.type === _vm.TYPE_PASSWORD,
                        'has-before': _vm.beforeText,
                        'has-after': _vm.afterText,
                    },attrs:{"step":"any","data-vv-name":_vm.name,"data-vv-as":_vm.validatorLabel || _vm.label,"placeholder":typeof _vm.placeholder === 'String' ? _vm.placeholder : null,"type":"number"},domProps:{"value":(_vm.count)},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('enter')},function($event){return _vm.onValidInteger()}],"keypress":function($event){return _vm.onValidInteger()},"keydown":function($event){return _vm.onValidInteger()},"input":[function($event){if($event.target.composing)return;_vm.count=$event.target.value},function($event){return _vm.$emit('input', $event.target.value)}]}})]:[_c('input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.rules || ''),expression:"rules || ''"}],key:_vm.name,ref:"area",staticClass:"selector__area",class:{
                        'has-password': _vm.type === _vm.TYPE_PASSWORD,
                        'has-before': _vm.beforeText,
                        'has-after': _vm.afterText,
                    },attrs:{"step":"any","data-vv-name":_vm.name,"data-vv-as":_vm.validatorLabel || _vm.label,"placeholder":_vm.placeholder && !_vm.hasFull ? _vm.placeholder : null,"type":_vm.type || 'text',"autocomplete":_vm.autocomplete || 'off'},domProps:{"value":_vm.value},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('enter')},function($event){return _vm.$emit('keyup')}],"input":function($event){return _vm.$emit('input', $event.target.value)}}})],(_vm.afterText)?_c('div',{staticClass:"selector__afterText",on:{"click":_vm.onToggle}},[_vm._v(" "+_vm._s(_vm.afterText)+" ")]):_vm._e(),(_vm.isFilterType && !_vm.hasFull)?_c('div',{staticClass:"selector__nav",class:{ 'has-active': _vm.hasFull || _vm.hasActive },on:{"click":_vm.onToggle}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-magnifying-glass"}})],1):_vm._e(),(_vm.isFilterType && _vm.hasFull)?_c('div',{staticClass:"selector__clear",on:{"click":_vm.onClear}},[_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-xmark"}})],1):_vm._e(),(_vm.buttonIcon)?_c('div',{staticClass:"selector__option",class:{
                    'druk-is-disabled': !_vm.hasFull && !_vm.canSaveEmpty,
                    'has-animate': !_vm.staticButton,
                },on:{"click":function($event){return _vm.$emit('submit')}}},[_c('druk-icon',{attrs:{"name":_vm.buttonIcon,"color":!_vm.hasFull && !_vm.canSaveEmpty ? 'on-surface' : 'on-surface-variant'}})],1):_vm._e(),(_vm.hasShowLength)?_c('div',{staticClass:"selector__label selector__label--bottom",class:{ [`druk-l-surface-${_vm.surface}`]: _vm.surface }},[_vm._v(" "+_vm._s(_vm.$t('common.symbols'))+": "+_vm._s(_vm.value ? _vm.value.length : '0')),(_vm.maxLength)?[_vm._v("/"+_vm._s(_vm.maxLength))]:_vm._e()],2):_vm._e()],2),(_vm.errors.has(_vm.name))?_c('div',{staticClass:"selector__error"},[_c('span',{class:{ [`druk-l-surface-${_vm.surface}`]: _vm.surface }},[_vm._v(_vm._s(_vm.errorText || _vm.errors.first(_vm.name)))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }