<template>
    <div class="druk-c-dialog-option" :class="{ 'druk-is-inline': isInline, 'druk-is-unavailable': isUnavailable }" @click="onDialog">
        <div class="druk-c-dialog-option__wrapper">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import ModalAlert from './ModalAlert';

    export default {
        name: 'druk-dialog-option',

        props: {
            question: String,
            isInline: Boolean,
            isUnavailable: [Boolean, Number],
            fnWait: Function,
        },

        methods: {
            onDialog(e) {
                this.$modal.show(
                    ModalAlert,
                    {
                        text: this.question,
                        fnWait: () => this.$emit('success', e),
                    },
                    $MODAL_OPTIONS.alert,
                );
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-dialog-option {
        &.druk-is-inline {
            display: inline-block;
            vertical-align: text-top;
        }
        &.druk-is-unavailable {
            pointer-events: none;
        }
    }
</style>
