<template>
    <div
        :class="{
            [`${base}__footer`]: !!base,
            [`${base}__footer--alt`]: $fn.hasAccess(PERM_MODULE_EMPLOYEES, PERM_ACTION_DELETE),
        }">
        <div :class="{ [`${base}__options`]: base }">
            <div
                v-if="!item.deleted && $fn.hasAccess(PERM_MODULE_EMPLOYEES, PERM_ACTION_DELETE)"
                :class="{ [`${base}__footerItem`]: base }">
                <druk-dialog-option :isUnavailable="hasLoader" @success="onDeactivate">
                    <druk-button
                        :type="'outlined'"
                        :icon="'user-slash'"
                        :label="$t('employees.deactivation_confirmation_action')"
                        :isHighlighted="true"
                        :isWainting="hasLoader" />
                </druk-dialog-option>
            </div>
        </div>

        <div :class="{ [`${base}__options`]: base }">
            <div v-if="isEditMode" :class="{ [`${base}__footerItem`]: base }">
                <druk-button
                    :type="'outlined'"
                    :icon="'xmark'"
                    :label="$t('admin.btn.cancel')"
                    :isWainting="hasLoader"
                    @click="$emit('onCancel')" />
            </div>

            <div v-if="!item.deleted" :class="{ [`${base}__footerItem`]: base }">
                <druk-button
                    :icon="isEditMode ? 'floppy-disk' : 'pen'"
                    :label="isEditMode ? $t('btn.save') : $t('btn.edit')"
                    :isWainting="hasLoader"
                    @click="$emit('onStartSaving', $event)" />
            </div>

            <div v-else :class="{ [`${base}__footerItem _flex _align_center`]: base }">
                <prompt-limit v-if="hasReachedEmployeesLimit" :isToolTip="true" :isActivationType="true" />

                <druk-dialog-option :isUnavailable="hasLoader || hasReachedEmployeesLimit" @success="onActivate">
                    <druk-button
                        :icon="'user-check'"
                        :label="$t('employees.on_activate_employee')"
                        :isWainting="hasLoader"
                        :isDisabled="hasReachedEmployeesLimit" />
                </druk-dialog-option>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import ModalDeactivation from './ModalDeactivation';

    import PromptLimit from './PromptLimit';

    export default {
        name: 'employees-actions',

        components: {
            PromptLimit,
        },

        props: {
            item: Object,
            base: String,
            isEditMode: Boolean,
        },

        computed: {
            ...mapState({
                PERM_MODULE_EMPLOYEES: (state) => state.permissions.PERM_MODULE_EMPLOYEES,
                PERM_ACTION_DELETE: (state) => state.permissions.PERM_ACTION_DELETE,

                loadingGetRole: (state) => state.roles.loadingGetItem,
                loadingGetRoleTemplate: (state) => state.roles.templates.loadingGetItem,
                loadingRestoreItem: (state) => state.employees.loadingRestoreItem,
                loadingDeleteItem: (state) => state.employees.loadingDeleteItem,
            }),

            ...mapGetters({
                hasERPAccess: 'hasERPAccess',
                hasReachedEmployeesLimit: 'pricing/hasReachedEmployeesLimit',
            }),

            hasLoader() {
                return this.loadingGetRole || this.loadingGetRoleTemplate || this.loadingRestoreItem || this.loadingDeleteItem;
            },
        },

        methods: {
            ...mapActions({
                GET_ITEM: 'employees/GET_ITEM',
                RESTORE_ITEM: 'employees/RESTORE_ITEM',
                DELETE_ITEM: 'employees/DELETE_ITEM',
            }),

            async onDeactivate() {
                let resp = await this.DELETE_ITEM({ id: this.item.id, force: this.hasERPAccess, is_single: true });

                if (!resp.success) {
                    this.$modal.show(ModalDeactivation, {
                        item: this.item,
                        dependencies: resp.dependencies,
                        fnWait: () => this.DELETE_ITEM({ id: this.item.id, force: true, is_single: true }),
                    });
                } else this.GET_ITEM(this.item.id);
            },

            onActivate() {
                this.RESTORE_ITEM({ id: this.item.id, is_single: true });
            },
        },
    };
</script>
