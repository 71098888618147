import { render, staticRenderFns } from "./FormCheckboxGroup.vue?vue&type=template&id=7892c2fa&scoped=true"
import script from "./FormCheckboxGroup.vue?vue&type=script&lang=js"
export * from "./FormCheckboxGroup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7892c2fa",
  null
  
)

export default component.exports