<template>
    <div class="ranges__chart">
        <Bar :ref="'chart'" :chartData="chartConfig.data" :chartOptions="chartConfig.options" />
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import { Bar } from 'vue-chartjs/legacy';

    import mixinChart from '@mixins/mixinChart';

    export default {
        name: 'calc-modes-ranges-chart',

        mixins: [mixinChart],

        components: {
            Bar,
        },

        props: {
            chartTitle: String,
            // baseColor: String,
        },

        computed: {
            ...mapState({
                ranges: (state) => state.calcModes.ranges.list,

                selectedCurrencyCode: (state) => state.selectedCurrencyCode,
            }),

            chartConfig() {
                return {
                    data: {
                        labels: this.rangesData.map((item) => item.label),

                        datasets: [
                            {
                                data: this.rangesData.map((item) => item.data),
                                borderWidth: 1,
                                borderColor: this.rangesData.map((item) => this.COLORS_MAP[item.color]?.border),
                                backgroundColor: this.rangesData.map((item) => this.COLORS_MAP[item.color]?.background),
                                hoverBackgroundColor: this.rangesData.map((item) => this.COLORS_MAP[item.color]?.backgroundHover),
                            },
                        ],
                    },

                    options: {
                        ...this.chartOptions,
                        scales: this.scalesPluginOptions,
                        plugins: {
                            datalabels: this.dataLabelsPluginOptions,
                            doughnutlabel: {
                                display: false,
                            },
                            legend: {
                                ...this.legendPluginOptions,
                                ...this.barLegendPluginOptions,
                            },
                            tooltip: {
                                ...this.tooltipPluginOptions,
                                ...this.barTooltipPluginOptions,
                            },
                        },
                    },
                };
            },

            rangesData() {
                let colors = Object.keys(this.COLORS_MAP);

                return this.ranges.map((range, index, list) => {
                    let colorIndex = index;
                    while (colorIndex > colors.length - 1) colorIndex -= index;

                    return {
                        data: range.price,
                        label: `${range.count} - ${+list[index + 1]?.count - 1 || '...'}`,
                        // color: 'water-blue',
                        color: colors[colorIndex],
                    };
                });
            },
        },
    };
</script>

<style lang="scss" scoped></style>
